import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

const orderOptions = [
  {
    key: '1',
    text: 'Lisensi Seat',
    value: 'Lisensi Seat',
    // image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/jenny.jpg' },
    icon: {color: 'green', inverted: true, circular: true, name: 'user circle'},
  },
  {
    key: '2',
    text: 'Negosiasi Harga',
    value: 'Negosiasi Harga',
    // image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' },
    icon: {color: 'red', inverted: true, circular: true, name: 'handshake'},
  },
]

const topicOptions = [
  {
    key: '1',
    text: 'Presentasi Online',
    value: 'Presentasi Online',
    // image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/jenny.jpg' },
    icon: {color: 'green', inverted: true, circular: true, name: 'tv'},
  },
  {
    key: '2',
    text: 'Negosiasi Harga',
    value: 'Negosiasi Harga',
    // image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' },
    icon: {color: 'red', inverted: true, circular: true, name: 'handshake'},
  },
  {
    key: '3',
    text: 'Kustom Layanan / Model Bisnis',
    value: 'Kustom Layanan / Model Bisnis',
    // image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' },
    icon: {color: 'orange', inverted: true, circular: true, name: 'code'},
  },
  {
    key: '4',
    text: 'Permintaan Dokumen',
    value: 'Permintaan Dokumen',
    // image: { avatar: true, src: 'https://react.semantic-ui.com/images/avatar/small/elliot.jpg' },
    icon: {color: 'teal', inverted: true, circular: true, name: 'file alternate outline'},
  },
]

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  setValue(v, e) {
    const param= []
    param[v]= e.target.value;
    this.setState({
      ...param,
    })
    console.log(v, e.target.value)
  }

  setSelected(v, e, k) {
    const param= []
    param[v]= k.value;
    this.setState({
      ...param,
    })
    console.log(v, e, k.value)
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{padding: '2em 1.5em', background: '#fff'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '0 0'}}>
              <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
                <Header.Content>
                  <strong>
                    {this.props.params && this.props.params.service=='stream' && <><em>{(this.props.order && 'quote') || 'info'}</em> <b>Stream!</b> Outbound</>}
                    {this.props.params && this.props.params.service=='flow' && <><em>{(this.props.order && 'quote') || 'info'}</em> <b>Flow!</b> Omni-Channel</>}
                    {this.props.params && this.props.params.service=='talk' && <><em>{(this.props.order && 'quote') || 'info'}</em> <b>Talk!</b> In-App Voice</>}
                    {this.props.params && this.props.params.service=='ring' && <><em>{(this.props.order && 'quote') || 'info'}</em> <b>Ring!</b> Office Phone</>}
                  </strong>
                  <Header.Subheader><strong>DENGAN HARGA <b>TERBAIK</b>.</strong></Header.Subheader>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    {screenWidth>600 && 
                      <Table.Cell width={1} rowSpan={2}>
                        {this.props.params && this.props.params.service=='stream' && <Icon name='send' color='yellow' circular inverted style={{fontSize: '1.5em'}} />}
                        {this.props.params && this.props.params.service=='flow' && <Icon name='comments' color='yellow' circular inverted style={{fontSize: '1.5em'}} />}
                        {this.props.params && this.props.params.service=='talk' && <Icon name='microphone' color='yellow' circular inverted style={{fontSize: '1.5em'}} />}
                        {this.props.params && this.props.params.service=='ring' && <Icon name='phone' color='yellow' circular inverted style={{fontSize: '1.5em'}} />}
                      </Table.Cell>
                    }
                    <Table.Cell width={15}>
                      {this.props.params && (this.props.params.service=='stream' || this.props.params.service=='flow') &&
                        <Form>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <label>HUBUNGI BALIK SAYA DI (EMAIL / HP)</label>
                              <input placeholder='Email / Nomor Telepon' onChange={this.setValue.bind(this, 'contact')}/>
                            </Form.Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                            {!this.props.order &&
                              <Form.Field>
                                <label>KEPERLUAN / PERMINTAAN</label>
                                <Dropdown onChange={this.setSelected.bind(this, 'info')}
                                  placeholder='Informasi yang diinginkan'
                                  fluid
                                  selection
                                  options={topicOptions}
                                />
                              </Form.Field>
                            }
                            <Form.Field>
                              <label>JUMLAH AGENT / SEAT</label>
                              <input placeholder='Jumlah Agent / User' onChange={this.setValue.bind(this, 'seat')} type='number' />
                            </Form.Field>
                          </Form.Group>
                          
                          <div style={{textAlign: 'right', marginTop: '4em'}}>
                            <Button as='a' onClick={this.props.closeModal.bind(this)} size='medium' color='teal' href={'https://api.whatsapp.com/send/?phone=6281296272484&text='+ (this.props.order ? 'Halo Pagi!, Kami tertarik layanan *'+ this.props.params.service.toUpperCase() +'*, dengan jumlah seat *'+ this.state.seat +'*, hubungi saya di '+ this.state.contact +', Terimakasih !' :  'Halo Pagi!, Mohon alokasikan waktu segera untuk *'+ this.state.info +'*, dengan jumlah seat *'+ this.state.seat +'*, hubungi saya di '+ this.state.contact +', Thanks Ya !') +'&app_absent=0'}>KIRIM PESAN</Button>
                          </div>
                        </Form>
                      }
                      {this.props.params && (this.props.params.service=='talk' || this.props.params.service=='ring') &&
                        <Form>
                          <Form.Group widths='equal'>
                            <Form.Field>
                              <label>HUBUNGI BALIK SAYA DI (EMAIL / HP)</label>
                              <input placeholder='Email / Nomor Telepon' onChange={this.setValue.bind(this, 'contact')} />
                            </Form.Field>
                          </Form.Group>
                          <Form.Group widths='equal'>
                            {!this.props.order &&
                              <Form.Field>
                                <label>KEPERLUAN / PERMINTAAN</label>
                                <Dropdown onChange={this.setSelected.bind(this, 'info')}
                                  placeholder='Informasi yang diinginkan'
                                  fluid
                                  selection
                                  options={topicOptions}
                                />
                              </Form.Field>
                            }
                            <Form.Field>
                              <label>{(this.props.params.service=='talk' && 'JUMLAH KAPASITAS LINE') || 'JUMLAH TITIK'}</label>
                              <input placeholder={(this.props.params.service=='talk' && 'Jumlah maks. puncak sambungan') || 'Jumlah titik / ekstensi'} onChange={this.setValue.bind(this, 'seat')} type='number' />
                            </Form.Field>
                          </Form.Group>
                          {this.props.params && (this.props.params.service=='talk') &&
                            <Form.Field>
                              <label>INFORMASI PENTING !</label>
                              Jumlah biaya dihitung berdasarkan <b>Jumlah Sambungan Puncak</b> / <strong>Saat Bersamaan</strong>, jumlah user/seat untuk <strong>Pelanggan</strong> dan <strong>Mitra / Merchant</strong> tidak dihitung sebagai <em>cost structure</em>.
                            </Form.Field>
                          }
                          {this.props.params && (this.props.params.service=='ring') &&
                            <Form.Field>
                              <label>INFORMASI PENTING !</label>
                              Jumlah biaya dihitung berdasarkan <b>Jumlah Titik</b> nomor yang akan digunakan dan dihubungkan dalam satu perusahaan.
                            </Form.Field>
                          }
                          
                          <div style={{textAlign: 'right', marginTop: '4em'}}>
                            <Button as='a' onClick={this.props.closeModal.bind(this)} size='medium' color='teal' href={'https://api.whatsapp.com/send/?phone=6281296272484&text='+ (this.props.order ? 'Halo Pagi!, Kami tertarik layanan *'+ this.props.params.service.toUpperCase() +'*, dengan jumlah kapasitas line / titik *'+ this.state.seat +'*, hubungi saya di '+ this.state.contact +', Terimakasih !' : 'Halo Pagi!, Mohon alokasikan waktu segera untuk *'+ this.state.info +'*, dengan jumlah kapasitas line / titik *'+ this.state.seat +'*, hubungi saya di '+ this.state.contact +', Thanks Ya !') +'&app_absent=0'}>KIRIM PESAN</Button>
                          </div>
                        </Form>
                      }
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.9em'}}>
                        <List.Item as='li' value='!'>
                          Permintaan akan dijawab <strong>maksimal</strong> dalam waktu <strong>24 jam</strong>.
                        </List.Item>
                        <List.Item as='li' value='!'>
                          Untuk <strong>response cepat</strong> silahkan masukkan <strong>nomor telepon</strong>.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              {/* <Divider />
              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={1} rowSpan={3}>
                      <Icon name='comments' color='yellow' circular inverted style={{fontSize: '1.5em'}} />
                    </Table.Cell>
                    <Table.Cell width={15}>
                      <Header as='h2'>
                        <Header.Content>
                          <b>Flow!</b> Omni-Channel
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={15}>
                      
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.9em'}}>
                        <List.Item as='li' value='!'>
                          Permintaan akan dijawab maksimal dalam waktu 24 jam.
                        </List.Item>
                        <List.Item as='li' value='!'>
                          Untuk response cepat silahkan masukkan nomor telepon.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider />

              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={1} rowSpan={3}>
                      <Icon name='microphone' color='yellow' circular inverted style={{fontSize: '1.5em'}} />
                    </Table.Cell>
                    <Table.Cell width={15}>
                      <Header as='h2'>
                        <Header.Content>
                          <b>Talk!</b> In-App Voice
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={15}>
                      
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.9em'}}>
                        <List.Item as='li' value='!'>
                          Permintaan akan dijawab maksimal dalam waktu 24 jam.
                        </List.Item>
                        <List.Item as='li' value='!'>
                          Untuk response cepat silahkan masukkan nomor telepon.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider />

              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={1} rowSpan={3}>
                      <Icon name='phone' color='yellow' circular inverted style={{fontSize: '1.5em'}} />
                    </Table.Cell>
                    <Table.Cell width={15}>
                      <Header as='h2'>
                        <Header.Content>
                          <b>Ring!</b> Office Phone
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={15}>
                      
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.9em'}}>
                        <List.Item as='li' value='!'>
                          Permintaan akan dijawab maksimal dalam waktu 24 jam.
                        </List.Item>
                        <List.Item as='li' value='!'>
                          Untuk response cepat silahkan masukkan nomor telepon.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table> */}
            </div>
          </div>
        </Container>
      </main>
    )
  }
} 

export default ContactPage
