import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import logo_crm from '../assets/logo-crm.png';
import logo_ticketing from '../assets/logo-ticketing.png';
import logo_channnel from '../assets/logo-channel.png';
import FlowAnimate from './FlowAnimate'

class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center', margin: '0'}}>
            <Header.Content>
              Pastikan <i><b>Flow!</b> Omni-Channel</i>
              <div style={{fontWeight: '400', fontSize: '.8em'}}>Interaksi Bisa Datang Darimana Saja / Kapan Saja!</div>
              {/* <Header.Subheader style={{padding: '0 20%'}} >
                Cukup dengan <i>single experience</i> untuk setiap interaksi dengan <strong>Pelanggan</strong> yang datang darimana saja, untuk meningkatkan <em>engagement</em> dan <em>conversion</em>.
              </Header.Subheader> */}
            </Header.Content>
          </Header>

          <div style={{textAlign: 'center',marginBottom: '2em'}}>
            {/* <Button size='big' color='blue' onClick={this.props.openModal.bind(this, 'contact', {service: 'flow'})}>Hubungi Sales!</Button> */}
            <Button as='a' size='big' color='pink' href='/pilihan-harga-terbaik-omni-channel-untuk-semua-kanal-interaksi/pricing/flow-omni-channel/'>Cek Harga!</Button>
            
            <Divider hidden />
            <FlowAnimate />
          </div>

          {/* <Divider horizontal>TENTANG DESK COLLECTION</Divider> */}
          <Container>
            <div style={{padding: screenWidth<1000 ? '1.5em' : '1.5em 10%', textAlign: 'center', marginBottom: '2em'}}>
              <p style={{fontSize: '1.5em'}}>
                Pengalaman baru Perusahaan untuk berinteraksi dengan <strong>Pelanggan</strong> yang datang darimana saja, <b>Telepon</b>, <b>Chat</b>, <b>SMS</b>, <b>Email</b>, <b>Whatsapp</b>, <b>Facebook</b>, <b>Instagram</b> dan <b>Twitter</b>.
                Dengan memastikan tingkat <em>engagement</em> dan <em>conversion</em> <strong>Pelanggan</strong> menjadi naik berlipat dengan dukungan platform <cite>Ticketing</cite> dan <cite>CRM</cite> yang terintegrasi.
              </p>
            </div>
          </Container>

          <div style={{background: '#e3f3f3', padding: screenWidth<1200 ? '1.5em 2em' : '1.5em 10%'}}>
            <Container>
              <Card.Group itemsPerRow={3} style={{padding: '3em 0 2em 0'}} stackable>
                <Card>
                  <Image alt="CRM Terintegrasi" size='small' src={logo_crm} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          CRM
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                      Platform CRM terintegrasi untuk menyimpan data <strong>Pelanggan</strong> dengan atribut dan transaksi yang telah dilakukan.  Interaksi dilakukan secara langsung melalui platform <cite>CRM</cite> dengan kanal pilihan.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>
              
                <Card fluid>
                  <Image alt="Dukungan multi kanal Telepon, Chat, Whatsapp dan Social Media" src={logo_channnel} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Pilihan Kanal Interaksi
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        Gunakan kanal komunikasi yang disediakan sesuai kebutuhan, untuk berinteraksi dengan <strong>Pelanggan</strong>. Dari komunikasi konvensional hingga digital berbasis data.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>

                <Card>
                  <Image alt="Ticketing terintegrasi dengan aplikasi" size='small' src={logo_ticketing} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Ticketing
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        Simpan setiap interaksi <strong>Pelanggan</strong> yang berhubungan dengan dukungan operasional layanan, dukungan pengangan komplain dan gangguan dalam satu platform <cite>Ticketing</cite> yang terintegrasi.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Container>
          </div>
          
        </Container>
      </main>
    )
  }
} 

export default ProductPage
