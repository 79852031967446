import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '0 0'}}>
              <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
                <Header.Content>
                  Harga <em><b>Flow!</b> <strong>Omni-Channel</strong></em> 
                  <div style={{fontSize: '.8em'}}>Sudah Termasuk <i>CRM</i> dan <i>Ticketing</i> Terintegrasi</div>
                  <Header.Subheader style={{padding: '0 10%'}}>Pengalaman yang sama berinteraksi dengan Pelanggan yang datang dari kanal apapun, untuk meningkatkan <em>engagement</em> dan <em>conversion</em>.</Header.Subheader>
                </Header.Content>
              </Header>

              <div style={{padding: screenWidth>1000 ? '3em 15% 3em 15%' : '2em 1.5em', background: '#f9f6f5'}}>
                <div style={{textAlign: 'center', marginBottom: '3em'}}>
                  <Button size='big' color='red' onClick={this.props.openModal.bind(this, 'order', {service: 'flow'})}>Saya Tertarik !</Button>
                </div>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={3}>
                          <Icon name='spy' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                        }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Seat / User</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Biaya dihitung berdasarkan jumlah Agent yang terdaftar.
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>450K / User</strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Kanal interaksi In-App Voice, Chat dan Social Media
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>Gratis</strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={3}>
                          <Icon name='folder open outline' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Opsi Tambahan Modul Aplikasi</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Ticketing
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>0 / User</strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Customer Relationship Management
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>0 / User</strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={4}>
                          <Icon name='adjust' circular inverted color='yellow' style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Opsi Tambahan Kanal Interaksi</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Email
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>35 / Sent</strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Voice (Telco Provider)
                        </p>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <strong style={{fontSize: '1.1em'}}>11 / Detik</strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Whatsapp, SMS dan kanal lainnya
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <Button color='grey' size='small' onClick={this.props.openModal.bind(this, 'contact', {service: 'flow'})}>Hubungi Sales!</Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>

              <Divider hidden />
              <List as='ol' style={{color: '#333', fontSize: '.9em', padding: '0 1.5em'}}>
                <List.Item as='li' value='!'>
                Biaya tambahan yang dikenakan oleh pihak ketiga akan ditagihkan bilamana belum termasuk dalam komponen biaya, seperti <i>Provider Telco</i>, <i>Messaging</i> dan <i>Social Media</i>.
                </List.Item>
                <List.Item as='li' value='!'>
                  Kanal Voice digunakan untuk Telepon dan IVR, Messaging meliputi SMS, Email dan Whatsapp dan Social Media untuk Facebook, Instagram dan Twitter.
                </List.Item>
                <List.Item as='li' value='!'>
                  Untuk keperluan <strong>negosiasi</strong> harga atau <strong>kustomisasi</strong> baik layanan dan model bisnis silahkan hubungi sales kami untuk diskusi.
                </List.Item>
              </List>
            </div>
          </div>
        </Container>
      </main>
    )
  }
} 

export default PricingPage
