import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import bg_halopagi from '../assets/bg-halopagi.png';
import bg_halopagi_1 from '../assets/bg-halopagi-1.png';
import bg_halopagi_2 from '../assets/bg-halopagi-2.png';
import bg_halopagi_2_a from '../assets/bg-halopagi-2-a.png';
import bg_halopagi_2_b from '../assets/bg-halopagi-2-b.png';
import bg_halopagi_3 from '../assets/bg-halopagi-3.png';
import bg_halopagi_4 from '../assets/bg-halopagi-4.png';

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = React.useState(false);
  
  // console.log('!!! USE EFFECT', element.current)
      
  React.useEffect(() => {

      const observer = new IntersectionObserver(
          ([entry]) => {
              if (entry.isIntersecting) {
                  setState(entry.isIntersecting);
                  observer.unobserve(element.current);
              } else {
                setState(false);
              }
          },
          {
              rootMargin
          }
      );

      element.current && observer.observe(element.current);

      return () => {
        element.current && observer.unobserve(element.current);
      };
  }, []);

  return isVisible;
};

const HalopagiAnimate = (props) => {
  const ref = React.useRef();
  // const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
  const inViewport = useIntersection(ref, '-50%'); // Trigger if 200px is visible from the element
  if (inViewport) {
    console.log('!!! INVIEWPORT', ref.current)
  }
  return (
    <div style={{overflow: 'hidden', margin: '0 auto', textAlign: 'center'}} ref={ref}>
      <div style={{height: '450px', background: 'url('+ bg_halopagi +') no-repeat center bottom'}} />
      {inViewport && <div className='animfadeout1' style={{visibility: 'hidden', marginTop: '-450px', height: '450px', background: 'url('+ bg_halopagi_1 +') no-repeat center bottom'}} />}
      {/* {inViewport && <div className='animfadeout2' style={{marginTop: '-450px', height: '450px', background: 'url('+ bg_halopagi_2 +') no-repeat center bottom'}} />} */}
      {inViewport && <div className='animfadeout2a' style={{visibility: 'hidden', marginTop: '-450px', height: '450px', background: 'url('+ bg_halopagi_2_a +') no-repeat center bottom'}} />}
      {inViewport && <div className='animfadeout2b' style={{visibility: 'hidden', marginTop: '-450px', height: '450px', background: 'url('+ bg_halopagi_2_b +') no-repeat center bottom'}} />}
      {inViewport && <div className='animfadeout3' style={{visibility: 'hidden', marginTop: '-450px', height: '450px', background: 'url('+ bg_halopagi_3 +') no-repeat center bottom'}} />}
      {inViewport && <div className='animfadeout4' style={{visibility: 'hidden', margin: '-450px auto 0 auto', height: '450px', background: 'url('+ bg_halopagi_4 +') no-repeat center bottom'}} />}
    </div>
  );
}

export default HalopagiAnimate;
