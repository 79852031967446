import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class DocFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <footer style={{background: '#fff'}}>
        <Container fluid style={{padding: '1em 0em 1.5em 0'}}>
          <div style={{display: screenWidth>600 ? 'flex' : 'block'}}>
            <div style={{flex: '1', padding: '.5em 0em 0 0em'}}>
              {/* <List celled horizontal>
                <List.Item as='a' href='/about-us/'>About</List.Item>
                <List.Item as='a' href='/contributor/'>Contributor</List.Item>
                <List.Item as='a' href='/feedback/'>Feedback</List.Item>
              </List> */}
            </div>
            <div style={{padding: '.25em 0em'}}>
              <Header as='a' style={{cursor: 'pointer', margin: 0}} href='/'>
                <Image alt="Halo Pagi! Communication Platform" src={'https://halopagi.com/asset/logo-small.png'} verticalAlign='middle' />
                <Header.Content>
                  <b>Know How!</b> Documentation
                  <Header.Subheader>
                    Definitely Your Communication Platform, by <strong>Halo Pagi!</strong>
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
        </Container>
      </footer>
      
    )
  }
} 

export default DocFooter
