import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import reportWebVitals from './reportWebVitals';

import 'semantic-ui-css/semantic.min.css'
import './styles/styles.css'

ReactDOM.hydrate(
// ReactDOM.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

reportWebVitals();
