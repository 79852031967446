import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import BlogFooter from './BlogFooter';

import axios from 'axios';

class ContentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  componentDidMount() {
    // FORWARD AGENT STATUS VIA AXIOS
    // axios.post(campaign.webApiEndPoint + '/stateAgent', {
    //   sessionId: 'Fred',
    //   agentId: '',
    //   agentName: '',
    //   customerId: '',
    //   phoneNumber: '',
    //   state: '',
    //   info: '',
    //   time: '',
    // })
    // .then(function (response) {
    //   console.log(response);
    //   //UPDATE 
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

    axios.get('https://halopagi.com', {})
    .then(resp=>{
      console.log(resp);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(<>
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 1.5em', background: '#fff'}}>
          <Header as='h1'>
            <Header.Content>
              API Secret Key
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>

          <Header as='h3'>
            <Header.Content>
              Tentang <cite>Secret Key</cite>
              <Header.Subheader>

              </Header.Subheader>
            </Header.Content>
          </Header>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sollicitudin tempor id eu nisl nunc mi ipsum. Ultrices dui sapien eget mi proin. Id eu nisl nunc mi ipsum faucibus vitae aliquet nec. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum. Ornare aenean euismod elementum nisi quis eleifend quam. Mauris pellentesque pulvinar pellentesque habitant morbi tristique. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque.
          </p>

          <Header as='h3'>
            <Header.Content>
              Cara Memperoleh <cite>Secret Key</cite>
              <Header.Subheader></Header.Subheader>
            </Header.Content>
          </Header>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sollicitudin tempor id eu nisl nunc mi ipsum. Ultrices dui sapien eget mi proin. Id eu nisl nunc mi ipsum faucibus vitae aliquet nec. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Nunc scelerisque viverra mauris in aliquam sem fringilla ut morbi. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum. Ornare aenean euismod elementum nisi quis eleifend quam. Mauris pellentesque pulvinar pellentesque habitant morbi tristique. In nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque.
          </p>
        </Container>
      </main>
      {/* <BlogFooter screenWidth={screenWidth} /> */}
    </>)
  }
} 

export default ContentPage
