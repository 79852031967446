import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class DocHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth, path }= this.props
    
    return(
      <header>
        <Container fluid>
          <div style={{background: 'rgba(255, 203, 0, .95)', position: 'fixed', width: '100%', zIndex: '999', padding: '1em 1em .5em .3em'}}>
            <div style={{display: 'flex'}}>
              <Header as='a' style={{margin: '.1em 1em 0 1em', cursor: 'pointer'}} href='/'>
                <Icon name='long arrow alternate left' style={{fontSize: '1em'}} color='red' />
                {screenWidth>640 &&
                <Header.Content>
                  <b>Halo Pagi! </b>
                  {screenWidth>900 &&
                    <span style={{color: '#444'}}>Communication Platform</span>
                  }
                </Header.Content>}
              </Header>
              <div style={{flex: '1'}}></div>
              {screenWidth>640 && <nav>
                <div style={{display: 'flex'}}>
                  <div style={{borderRight: '1px solid #ccc'}}> 
                    <Header as='a' color={path=='stream' || !path ? 'blue' : 'black'} style={{fontWeight: path=='stream' || !path ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/stream-outbound/'>
                      <Header.Content style={{fontWeight: 'bold'}}><b>Stream!</b> Outbound</Header.Content>
                    </Header>
                  </div>
                  <div style={{}}>
                    <Header as='a' color={path=='flow' ? 'blue' : 'black'} style={{fontWeight: path=='flow' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/flow-omni-channel/'>
                      <Header.Content style={{fontWeight: 'bold'}}><b>Flow!</b> Omni-Channel</Header.Content>
                    </Header>
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='talk' ? 'blue' : 'black'} style={{fontWeight: path=='talk' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/talk-in-app-voice/'>
                      <Header.Content style={{fontWeight: 'bold'}}><b>Talk!</b> In-App Voice</Header.Content>
                    </Header>
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='ring' ? 'blue' : 'black'} style={{fontWeight: path=='ring' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/ring-office-phone/'>
                      <Header.Content style={{fontWeight: 'bold'}}><b>Ring!</b> Office Phone</Header.Content>
                    </Header>
                  </div>
                </div>
              </nav>}
              {screenWidth<=640 && <>
                <div style={{borderRight: '1px solid #ccc'}}> 
                  <Header as='a' color={path=='stream' || !path ? 'blue' : 'black'} style={{fontWeight: path=='stream' || !path ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/stream-outbound/'>
                    <Header.Content><b>Stream!</b></Header.Content>
                  </Header>
                </div>
                <div style={{}}>
                  <Header as='a' color={path=='flow' ? 'blue' : 'black'} style={{fontWeight: path=='flow' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/flow-omni-channel/'>
                    <Header.Content><b>Flow!</b></Header.Content>
                  </Header>
                </div>
                <div style={{borderLeft: '1px solid #ccc'}}>
                  <Header as='a' color={path=='talk' ? 'blue' : 'black'} style={{fontWeight: path=='talk' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/talk-in-app-voice/'>
                    <Header.Content><b>Talk!</b></Header.Content>
                  </Header>
                </div>
                <div style={{borderLeft: '1px solid #ccc'}}>
                  <Header as='a' color={path=='ring' ? 'blue' : 'black'} style={{fontWeight: path=='ring' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/doc/ring-office-phone/'>
                    <Header.Content><b>Ring!</b></Header.Content>
                  </Header>
                </div>
              </>}
            </div>
          </div>
        </Container>
      </header>
    )
  }
} 

export default DocHeader
