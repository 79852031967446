import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '0 0'}}>
              <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
                <Header.Content>
                  Harga <b>Stream!</b> <strong>Outbound</strong> 
                  {/* <div style={{fontWeight: '400', fontSize: '.8em'}}>Collection, Telesales, Survey dan Verification</div> */}
                  <div style={{fontWeight: '400', fontSize: '.8em'}}>Pilihan Harga Terbaik Lisensi Seat dan Bundling Voice</div>
                  <Header.Subheader style={{padding: '0 10%'}}>Satu aplikasi untuk <b>Desk Collection</b>, <b>Telesales</b>, <b>Survey</b> dan <b>Verification</b>, dengan fitur multi-channel platform berbasis <i>Voice</i>, <i>IVR</i>, <i>SMS</i>, <i>Email</i>, <i>Whatsapp</i>, <i>Twitter</i>.</Header.Subheader>
                </Header.Content>
              </Header>

              <div style={{padding: screenWidth>1000 ? '3em 15% 3em 15%' : '2em 1.5em', background: '#f9f6f0'}}>
                <div style={{textAlign: 'center', marginBottom: '3em'}}>
                  <Button size='big' color='red' onClick={this.props.openModal.bind(this, 'order', {service: 'stream'})}>Saya Tertarik !</Button>
                </div>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={3}>
                          <Icon name='spy' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Seat / User</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Biaya dihitung berdasarkan jumlah Agent yang terdaftar.
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>650K / User</strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={9}>
                        <p style={{fontSize: '1.1em'}}>
                          Biaya penggunaan pulsa <i>Voice</i> telekomunikasi untuk telepony.
                        </p>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <strong style={{fontSize: '1.1em'}}>11 / Detik</strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={2}>
                          <Icon name='credit card outline' circular inverted color='yellow' style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Pay Per Use</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={9}>
                        <p style={{fontSize: '1.1em'}}>
                          Biaya dihitung berdasarkan penggunaan <i>Voice</i>, <strong>tanpa biaya aplikasi</strong>.
                        </p>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <strong style={{fontSize: '1.1em'}}>16 / Detik</strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={2}>
                          <Icon name='adjust' circular inverted color='yellow' style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Opsi Tambahan Kanal Interaksi</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={9}>
                        <p style={{fontSize: '1.1em'}}>
                          Dukungan layanan untuk multi channel seperti <i>IVR / Robo Call</i>, <i>SMS</i>, <i>Email</i>, <i>Whatsapp</i> dan <i>Twitter</i>.
                        </p>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Button color='grey' size='small' onClick={this.props.openModal.bind(this, 'contact', {service: 'stream'})}>Hubungi Sales!</Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>

              <Divider hidden />
              <List as='ol' style={{color: '#333', fontSize: '.9em', padding: '0 1.5em'}}>
                <List.Item as='li' value='!'>
                  Biaya tidak termasuk tagihan yang dikenakan oleh pihak ketiga, seperti <i>Provider Telco</i>, <i>Messaging</i> dan <i>Social Media</i>.
                </List.Item>
                <List.Item as='li' value='!'>
                  Kanal Voice digunakan untuk Telepon dan IVR, Messaging meliputi SMS, Email dan Whatsapp dan Social Media untuk Twitter.
                </List.Item>
                <List.Item as='li' value='!'>
                  Untuk keperluan <strong>negosiasi</strong> harga atau <strong>kustomisasi</strong> baik layanan dan model bisnis silahkan hubungi sales kami untuk diskusi.
                </List.Item>
              </List>
            </div>
          </div>
        </Container>
      </main>
    )
  }
} 

export default PricingPage
