import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '0 0'}}>
              <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
                <Header.Content>
                  Harga <em><b>Ring!</b> <strong>Office Phone</strong></em> 
                  <div style={{fontSize: '.8em'}}>Kolaborasi perusahaan dengan komunikasi tanpa batas.</div>
                  <Header.Subheader style={{padding: '0 10%'}}>Menghubungkan kantor cabang perusahaan yang berjauhan dengan mudah dan murah.</Header.Subheader>
                </Header.Content>
              </Header>

              <div style={{padding: screenWidth>1000 ? '3em 15% 3em 15%' : '2em 1.5em', background: '#faf9ea'}}>
                <div style={{textAlign: 'center', marginBottom: '3em'}}>
                  <Button size='big' color='red' onClick={this.props.openModal.bind(this, 'order', {service: 'ring'})}>Saya Tertarik !</Button>
                </div>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={2}>
                          <Icon name='hashtag' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Jumlah Titik / Nomor <i>Extension</i></strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                        Biaya dihitung berdasarkan jumlah titik sambungan atau nomor ekstensi.
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>25K / EXT</strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={2}>
                          <Icon name='music' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                          <strong>Opsi Tambahan Fitur <i>Enterprise</i></strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                        Opsional fitur untuk <em>enterprise</em> seperti bisa dihubungi dari <i>Provider Telco</i> dengan nomor <i>Call Center</i> tertentu, <i>IVR</i>, <i>Background Music</i>, <i>Conference Call</i>, <i>Audio Recording</i>.
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <Button color='grey' size='small' onClick={this.props.openModal.bind(this, 'contact', {service: 'ring'})}>Hubungi Sales!</Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>

              <Divider hidden />
              <List as='ol' style={{color: '#333', fontSize: '.9em', padding: '0 1.5em'}}>
                <List.Item as='li' value='!'>
                  Biaya tidak termasuk tagihan yang dikenakan oleh pihak ketiga, seperti <i>Provider Telco</i> bilamana digunakan untuk inbound/outbound panggilan ke nomor <i>Provider Telco</i>.
                </List.Item>
                <List.Item as='li' value='!'>
                  Biaya lain yang mungkin timbul atau bilamana diperlukan seperti  biaya Instalasi, Integrasi dan Deskphone akan ditulis dan dinyatakan di dok. <i>Quotation</i>.
                </List.Item>
                <List.Item as='li' value='!'>
                  Untuk keperluan <strong>negosiasi</strong> harga atau <strong>kustomisasi</strong> baik layanan dan model bisnis silahkan hubungi sales kami untuk diskusi.
                </List.Item>
              </List>
            </div>
          </div>
        </Container>
      </main>
    )
  }
} 

export default PricingPage
