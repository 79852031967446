import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class BlogHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth, path }= this.props
    
    return(
      <header>
        <Container fluid>
          <div style={{background: 'rgba(255, 203, 0, .95)', position: 'fixed', width: '100%', zIndex: '999', padding: '1em 1em .5em .3em'}}>
            <div style={{display: 'flex'}}>
              <Header as='a' style={{margin: '.1em 1em 0 1em', cursor: 'pointer'}} href='/'>
                <Icon name='long arrow alternate left' style={{fontSize: '1em'}} color='red' />
                {screenWidth>600 &&
                <Header.Content>
                  <b>Halo Pagi!</b> <span style={{color: '#444'}}>Communication Platform</span>
                </Header.Content>}
              </Header>
              <div style={{flex: '1'}}>
                
              </div>

              {screenWidth>640 && <nav>
                <div style={{display: 'flex'}}>
                  <div style={{borderRight: '1px solid #ccc'}}> 
                    <Header as='a' color={path=='exclusive' || !path ? 'blue' : 'black'} style={{margin: '0', fontWeight: path=='exclusive' || !path ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/exclusive/'>
                      <Header.Content>Exclusive Story</Header.Content>
                    </Header>
                  </div>
                  <div style={{borderRight: '1px solid #ccc'}}> 
                    <Header as='a' color={path=='stream' ? 'blue' : 'black'} style={{fontWeight: path=='stream' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/stream-outbound/'>
                      <Header.Content><b>Stream!</b> Outbound</Header.Content>
                    </Header>
                  </div>
                  <div style={{}}>
                    <Header as='a' color={path=='flow' ? 'blue' : 'black'} style={{fontWeight: path=='flow' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/flow-omni-channel/'>
                      <Header.Content><b>Flow!</b> Omni-Channel</Header.Content>
                    </Header>
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='talk' ? 'blue' : 'black'} style={{fontWeight: path=='talk' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/talk-in-app-voice/'>
                      <Header.Content><b>Talk!</b> In-App Voice</Header.Content>
                    </Header>
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='connect' ? 'blue' : 'black'} style={{fontWeight: path=='connect' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/ring-office-phone/'>
                      <Header.Content><b>Ring!</b> Office Phone</Header.Content>
                    </Header>
                  </div>
                </div>
              </nav>}
              {screenWidth<=640 && <>
                <div style={{borderRight: '1px solid #ccc'}}> 
                  <Header as='a' color={path=='exclusive' || !path ? 'blue' : 'black'} style={{fontWeight: path=='exclusive' || !path ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/exclusive/'>
                    <Header.Content>Exclusive</Header.Content>
                  </Header>
                </div>
                <div style={{borderRight: '1px solid #ccc'}}> 
                  <Header as='a' color={path=='stream' ? 'blue' : 'black'} style={{fontWeight: path=='stream' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/stream-outbound/'>
                    <Header.Content>Stream!</Header.Content>
                  </Header>
                </div>
                <div style={{}}>
                  <Header as='a' color={path=='flow' ? 'blue' : 'black'} style={{fontWeight: path=='flow' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/blog/flow-omni-channel/'>
                    <Header.Content>Flow!</Header.Content>
                  </Header>
                </div>
                <div style={{borderLeft: '1px solid #ccc'}}>
                  <Header as='a' color={path=='talk' ? 'blue' : 'black'} style={{fontWeight: path=='talk' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/blog/talk-in-app-voice/'>
                    <Header.Content>Talk!</Header.Content>
                  </Header>
                </div>
                <div style={{borderLeft: '1px solid #ccc'}}>
                  <Header as='a' color={path=='connect' ? 'blue' : 'black'} style={{fontWeight: path=='connect' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/blog/ring-office-phone/'>
                    <Header.Content>Ring!</Header.Content>
                  </Header>
                </div>
                <div style={{flex: '1'}}></div>
              </>}
            </div>
          </div>
        </Container>
      </header>
    )
  }
} 

export default BlogHeader
