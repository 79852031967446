import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'



import BlogHeader from './BlogHeader'
import BlogFooter from './BlogFooter'

import ContentPage from './ContentPage'
import IndexPage from './IndexPage'

class BlogPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  componentDidMount() {

  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(<div>
      <BlogHeader screenWidth={screenWidth} />
      
      <div style={{display: 'flex', minHeight: screenHeight}}>
        <div style={{width: '250px', background: '#f5f5f5'}}>
          <IndexPage screenWidth={screenWidth} screenHeight={screenHeight}/>
        </div>
        <div style={{flex:'1'}}>
          <ContentPage screenWidth={screenWidth} screenHeight={screenHeight}/>
          <BlogFooter screenWidth={screenWidth} />
        </div>
      </div>
    </div>)
  }
} 

export default BlogPage
