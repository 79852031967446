import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'
import SupplementPage from './SupplementPage'

class VerificationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              Platform Online <cite>Verification</cite> <br />Tepat Untuk Validasi Data Pelanggan
              <Header.Subheader style={{padding: '0 20%'}} >
                Berbasis <em>multi-channel</em>, dengan kanal <b>Voice</b>, <b>SMS</b>, <b>Email</b>, <b>Whatsapp</b> dan <b>Twitter</b> untuk melakukan proses <i>verification</i>, sehingga memperoleh jaminan kolektibilitas yang tinggi dengan memastikan bahwa informasi diterima oleh <strong>Pelanggan</strong>, Stream! Outbound Campaign.
              </Header.Subheader>
            </Header.Content>
          </Header>

          <div style={{textAlign: 'center',marginBottom: '8em'}}>
            <Button size='big' color='blue'>Demo?, Hubungi Sales</Button>
            <div style={{height: '450px', margin: '2em 0', overflow: 'hidden', background: '#f00'}}>
              <Image src={'https://assets.jalantikus.com/assets/cache/738/770/userfiles/2017/06/11/ipad-pro-promotion-display.jpeg'} wrapped ui={false} />
            </div>
          </div>

          {/* <Divider horizontal>TENTANG VERIFICATION</Divider> */}
          <div style={{margin: 'auto', padding: '0 5%', textAlign: 'center', marginBottom: '6em'}}>
            <Header as='h2' style={{display: 'block'}} color='orange'>
              <Header.Content>
                Apa itu platform Online <i>Verification</i> ?
                <Header.Subheader>Definisi dan pengertian Online <i>Verification</i>.</Header.Subheader>
              </Header.Content>
            </Header>
            <p style={{fontSize: '1.5em'}}>
              Validasi data <strong>Pelanggan</strong> perlu dilakukan untuk memastikan Perusahaan terhindar dari <em>fraud</em>, dengan melakukan verifikasi secara online diharapkan proses validasi lebih cepat dan mudah. Didukung dengan beberapa kanal komunikasi seperti <b>Telepon</b>, <b>IVR / Robo Call</b>, <b>SMS</b>, <b>Email</b>, <b>Whatsapp</b>  dan <b>Social Media</b> yang terintegrasi dan dilakukan secara online baik oleh <i> Agent </i> atau dikirim secara masif oleh sistem.
            </p>
            <p style={{fontSize: '1.5em'}}>
              Hasil setiap aktivitas <em>verification</em> disimpan sebagai <i>feedback</i> / <i>remark</i>, dimana setiap interaksi dengan <strong>Pelanggan</strong> disimpan dalam sistem, agar <strong>Pelanggan</strong> tidak dihubungi secara berulang bilamana sudah selesai.  Hasil dari aktivitas ini juga harus dapat disimpan dan disinkronkan dengan aplikasi internal di <em>backend</em> seperti <i>CRM</i> dan <i>Billing System</i>.
            </p>
          </div>

          <SupplementPage />
        </Container>
      </main>
    )
  }
} 

export default VerificationPage
