import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import logo_collection from '../assets/logo-collection.png';
import logo_telesales from '../assets/logo-telesales.png';
import logo_survey from '../assets/logo-survey.png';
import logo_verification from '../assets/logo-verification.png';

import StreamAnimate from './StreamAnimate';
import DashboardAnimate from './DashboardAnimate';
import MonitoringAnimate from './MonitoringAnimate';
import PerformanceAnimate from './PerformanceAnimate';
import CampaignAnimate from './CampaignAnimate';
import AgentAnimate from './AgentAnimate';
import FeedbackAnimate from './FeedbackAnimate';

class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      exp: 'dashboard'
    }
  }

  setExp(v) {
    this.setState({
      exp: v
    })
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center', margin: '0'}}>
            <Header.Content>
              Satu Platform <i><b>Stream!</b> Outbound</i>
              <div style={{fontWeight: '400', fontSize: '.8em'}}>Untuk <em>Desk Collection</em>, <em>Telesales</em>, <em>Survey</em> dan <em>Verification</em>!</div>
              {/* <Header.Subheader style={{padding: '0 20%'}} >
                Berbasis <em>multi-channel</em>, dengan kanal <b>Voice</b>, <b>SMS</b>, <b>Email</b>, <b>Whatsapp</b> dan <b>Twitter</b> untuk memastikan bahwa informasi yang akan disampaikan lebih mudah diterima dalam berinteraksi dengan <strong>Pelanggan</strong>, Stream! Outbound Campaign.
              </Header.Subheader> */}
            </Header.Content>
          </Header>

          <div style={{textAlign: 'center',marginBottom: '2em'}}>
            {/* <Button size='big' color='blue' onClick={this.props.openModal.bind(this, 'contact', {service: 'stream'})}>Cek Harga!</Button> */}
            <Button as='a' size='big' color='orange' href='/pilihan-harga-terbaik-lisensi-seat-user-dan-bundling-voice-untuk-desk-collection-telesales-survey-verification/pricing/stream-outbound/'>Cek Harga!</Button>
            
            <Divider hidden />
            <StreamAnimate />
          </div>

          {/* <Divider horizontal>TENTANG DESK COLLECTION</Divider> */}
          <Container>
            <div style={{padding: screenWidth<1000 ? '1.5em' : '1.5em 10%', textAlign: 'center', marginBottom: '2em'}}>
              <p style={{fontSize: '1.5em'}}>
                Cukup dengan satu platform <i><b>Stream!</b> Outbound</i> kini aktivitas untuk <i>Desk Collection</i>, <i>Telesales</i>, <i>Survey</i> dan <i>Verification</i> dapat dilakukan melalui satu pintu aplikasi oleh <i> Agent </i> perusahaan Anda. 
                Dengan jaminan informasi terkirim melalui fitur <i>multi-channel</i> <b>Telepon</b>, <b>IVR / Robo Call</b>, <b>SMS</b>, <b>Email</b>, <b>Whatsapp</b>  dan <b>Twitter</b> untuk memastikan <strong>Pelanggan</strong> benar-benar telah menerima.
              </p>
            </div>
          </Container>
          
          <div style={{background: '#f5eeec', padding: screenWidth<1200 ? '1.5em 2em' : '1.5em 10%'}}>
            <Container>
              <Card.Group itemsPerRow={4} style={{padding: '3em 0 2em 0'}} doubling stackable>
                <Card>
                  <Image alt="Desk Collection" size='small' src={logo_collection} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>
                    
                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Desk Collection
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    {/* <Card.Meta>
                      <span className='date'>Joined in 2015</span>
                    </Card.Meta> */}
                    <Card.Description>
                      <p>
                        Pengiriman dan koleksi pembayaran tagihan kini dapat dilakukan <i> Agent </i> dengan performansi <i>feedback</i> yang tinggi dan tingkat kenyamanan <strong>Pelanggan</strong> yang lebih terjamin.
                        {/* <Label as='a' size='large' icon><Icon name='arrow right' /> Pelajari</Label> */}
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>

                <Card>
                  {/* <div style={{height: '13em', textAlign: 'center', paddingTop: '2em'}}>
                    <Icon size='massive' name='closed captioning outline' color='yellow'/>
                  </div> */}
                  <Image alt="Telesales dan Telemarketing" size='small' src={logo_telesales} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>
                      
                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Online / Telesales
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    {/* <Card.Meta>
                      <span className='date'>Joined in 2015</span>
                    </Card.Meta> */}
                    <Card.Description>
                      <p>
                        Aktivitas penjualan secara online dan masif dengan fitur <i>predictive dial</i> dan <i>multi-channel</i>, untuk memastikan <i>conversion rate</i> penjualan selalu di peringkat terbaik.
                        {/* <Label as='a' size='large' icon><Icon name='arrow right' /> Pelajari</Label> */}
                      </p>
                    </Card.Description>
                  </Card.Content>
                  {/* <Card.Content extra>
                    
                  </Card.Content> */}
                </Card>
            
                <Card fluid>
                  {/* <Image src='/images/avatar/large/matthew.png' wrapped ui={false} /> */}
                  {/* <div style={{height: '13em', textAlign: 'center', paddingTop: '2em'}}>
                    <Icon size='massive' name='theme' color='yellow'/>
                  </div> */}
                  <Image alt="Survey Online" size='small' src={logo_survey} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Customer Survey
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    {/* <Card.Meta>
                      <span className='date'>Joined in 2015</span>
                    </Card.Meta> */}
                    <Card.Description>
                      <p>
                      Tentukan rencana strategis <strong>Perusahaan</strong> berdasarkan <em>customer insight</em> yang valid, lakukan dengan <i>online survey</i> melalui berbagai pilihan kanal.
                        {/* <Label as='a' size='large' icon><Icon name='arrow right' /> Pelajari</Label> */}
                      </p>
                    </Card.Description>
                  </Card.Content>
                  {/* <Card.Content extra>
                    
                  </Card.Content> */}
                </Card>

                <Card fluid>
                  {/* <div style={{height: '13em', textAlign: 'center', paddingTop: '2em'}}>
                    <Icon size='massive' name='lab' color='yellow'/>
                  </div> */}
                  <Image alt="Online Verification" size='small' src={logo_verification} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Cust. Verification
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    {/* <Card.Meta>
                      <span className='date'>Joined in 2015</span>
                    </Card.Meta> */}
                    <Card.Description>
                      <p>
                        Verifikasi <strong>Pelanggan</strong> mudah dilakukan, dengan proses panggilan dan pengiriman pesan secara otomatis dan masif,  bandingkan dan pastikan data verifikasi valid.
                        {/* <Label as='a' size='large' icon><Icon name='arrow right' /> Pelajari</Label> */}
                      </p>
                    </Card.Description>
                  </Card.Content>
                  {/* <Card.Content extra>
                    
                  </Card.Content> */}
                </Card>
              </Card.Group>
            </Container>
          </div>
          <div style={{textAlign: 'center'}}>
            <div style={{padding: '2em 0 0 0', background: '#fafafa'}}>
              <Container style={{textAlign: 'left'}}>
                <Header as='h2' color='blue' style={{margin: '0 0 .2em 0'}}>
                  <Icon name='rocket' />
                  <Header.Content>
                    <strong>Pengalaman Baru !</strong>
                    <Header.Subheader>Dengan tampilan dan konten yang lebih nyaman dan informatif - <strong style={{color: '#333'}}>{this.state.exp.toUpperCase()}</strong></Header.Subheader>
                  </Header.Content>
                </Header>
                <Divider />
              </Container>

              {this.state.exp=='dashboard' && <DashboardAnimate />}
              {this.state.exp=='monitoring' && <MonitoringAnimate />}
              {this.state.exp=='performance' && <PerformanceAnimate />}
              {this.state.exp=='campaign' && <CampaignAnimate />}
              {this.state.exp=='agent console' && <AgentAnimate />}
              {this.state.exp=='feedback / remark' && <FeedbackAnimate />}

              <div style={{padding: '1em 0 2em 0', background: '#fafafa'}}>
                <List celled horizontal style={{fontSize: '1.5em', fontWeight: 'bold'}}>
                  <List.Item as='a' style={{color: this.state.exp=='dashboard' && '#c31'}} onClick={this.setExp.bind(this, 'dashboard')}>DASHBOARD</List.Item>
                  <List.Item as='a' style={{color: this.state.exp=='monitoring' && '#c31'}} onClick={this.setExp.bind(this, 'monitoring')}>MONITORING</List.Item>
                  <List.Item as='a' style={{color: this.state.exp=='performance' && '#c31'}} onClick={this.setExp.bind(this, 'performance')}>PERFORMANCE</List.Item>
                  <List.Item as='a' style={{color: this.state.exp=='campaign' && '#c31'}} onClick={this.setExp.bind(this, 'campaign')}>CAMPAIGN</List.Item>
                  <List.Item as='a' style={{color: this.state.exp=='agent console' && '#c31'}} onClick={this.setExp.bind(this, 'agent console')}>AGENT CONSOLE</List.Item>
                  <List.Item as='a' style={{color: this.state.exp=='feedback / remark' && '#c31'}} onClick={this.setExp.bind(this, 'feedback / remark')}>FEEDBACK / REMARK</List.Item>
                </List>
              </div>
            </div>
          </div>
        </Container>
        
      </main>
    )
  }
} 

export default ProductPage
