import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
  Popup,
} from 'semantic-ui-react'

import { ReactSVG } from 'react-svg'
import logo  from '../assets/logo.svg'

const stylePopup = {
  borderRadius: '.3em 0',
  textAlign: 'justify',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '.8em',
  background: '#000',
  color: '#fff'
}

class HomeHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth, path }= this.props
    
    return(
      <header>
        <Container fluid>
          <div style={{background: 'rgba(255, 203, 0, .95)', position: 'fixed', width: '100%', zIndex: '999', padding: '1.5em .5em .5em .5em'}}>
            <div style={{display: 'flex'}}>
              {path=='home' &&
              <Header as='a' style={{margin: '-1em 1em 0 1em', cursor: 'pointer'}} href='/'>
                <div style={{textAlign: 'center', width: '3em', height: '3em', margin: '0', display: 'inline-block'}}>
                  <ReactSVG src={logo}/>
                </div>
                {/* <Icon name='home' style={{fontSize: '1em'}} color='red' /> */}
                {/* <Image alt="Halo Pagi! Communication Platform" src={'https://halopagi.com/asset/logo-small.png'} verticalAlign='middle' circular style={{border: '1px solid #aaa'}} size='small' /> */}
                {screenWidth>640 && 
                  <Header.Content style={{margin: '1em 0 0 .3em'}}>
                    <b>Halo Pagi! </b> 
                    {screenWidth>1200 && <span style={{color: '#444'}}>Communication Platform</span>}
                  </Header.Content>
                }
              </Header>}  
              {path!='home' && 
              <Header as='a' style={{margin: '.1em 1em 0 1em', cursor: 'pointer'}} href='/'>
                <Icon name='long arrow alternate left' style={{fontSize: '1em'}} color='red' />
                {screenWidth>800 &&
                <Header.Content>
                  <b>Halo Pagi!</b> 
                  {screenWidth>1000 && 
                    <span style={{color: '#444'}}>Communication Platform</span>
                  }
                </Header.Content>}
              </Header>}
              <div style={{flex: '1'}}></div>
              {screenWidth>640 && <nav>
                <div style={{display: 'flex'}}>
                  <div style={{borderRight: '1px solid #ccc'}}> 
                    <Popup style={stylePopup} content='Desk Collection, Telesales, Survey dan Verification, mulai 650K!' position= 'bottom right' inverted
                      trigger={
                      <Header as='a' color={path=='stream' || !path ? 'blue' : 'black'} style={{fontWeight: path=='stream' || !path ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/stream-outbound/'>
                        <Header.Content style={{fontWeight: 'bold'}}>
                          <b>Stream!</b> Outbound
                          <Label color='red' style={{position: 'fixed', margin: '-1.8em 0 0 -3.8em', padding: '.4em .4em .3em .4em', borderRadius: '.2em'}}>TERBAIK</Label>
                        </Header.Content>
                      </Header>
                    } />
                  </div>
                  <div style={{}}>
                    <Header as='a' color={path=='flow' ? 'blue' : 'black'} style={{fontWeight: path=='flow' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/flow-omni-channel/'>
                      <Header.Content style={{fontWeight: 'bold'}}>
                        <b>Flow!</b> Omni-Channel
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='talk' ? 'blue' : 'black'} style={{fontWeight: path=='talk' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/talk-in-app-voice/'>
                      <Header.Content style={{fontWeight: 'bold'}}>
                        <b>Talk!</b> In-App Voice
                      </Header.Content>
                    </Header>
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='connect' ? 'blue' : 'black'} style={{fontWeight: path=='connect' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/ring-office-phone/'>
                      <Header.Content style={{fontWeight: 'bold'}}>
                        <b>Ring!</b> Office Phone
                      </Header.Content>
                    </Header>
                  </div>
                </div>
              </nav>}
              {screenWidth<=640 && <>
                <div style={{borderRight: '1px solid #ccc'}}> 
                  <Header as='a' color={path=='stream' || !path ? 'blue' : 'black'} style={{fontWeight: path=='stream' || !path ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/stream-outbound/'>
                    <Header.Content>
                      <b>Stream!</b>
                      {/* <Header.Subheader>Outbound</Header.Subheader> */}
                      <Label color='red' style={{position: 'fixed', margin: '-1.8em 0 0 -3.8em', padding: '.4em .4em .3em .4em', borderRadius: '.2em'}}>TERBAIK</Label>
                    </Header.Content>
                  </Header>
                </div>
                <div style={{}}>
                  <Header as='a' color={path=='flow' ? 'blue' : 'black'} style={{fontWeight: path=='flow' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/flow-omni-channel/'>
                    <Header.Content>
                      <b>Flow!</b>
                      {/* <Header.Subheader>Omni-Channel</Header.Subheader> */}
                    </Header.Content>
                  </Header>
                </div>
                <div style={{borderLeft: '1px solid #ccc'}}>
                  <Header as='a' color={path=='talk' ? 'blue' : 'black'} style={{fontWeight: path=='talk' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/talk-in-app-voice/'>
                    <Header.Content>
                      <b>Talk!</b>
                      {/* <Header.Subheader>In-App Voice</Header.Subheader> */}
                    </Header.Content>
                  </Header>
                </div>
                <div style={{borderLeft: '1px solid #ccc'}}>
                  <Header as='a' color={path=='connect' ? 'blue' : 'black'} style={{fontWeight: path=='connect' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/ring-office-phone/'>
                    <Header.Content>
                      <b>Ring!</b>
                      {/* <Header.Subheader>Office Phone</Header.Subheader> */}
                    </Header.Content>
                  </Header>
                </div>
                <div style={{flex: '1'}}></div>
              </>}
            </div>
          </div>
        </Container>
      </header>
    )
  }
} 

export default HomeHeader
