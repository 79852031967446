import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import { useParams } from "react-router-dom"


import HomeHeader from './HomeHeader'
import HomeFooter from './HomeFooter'
import ProductPage from './ProductPage'
import AboutusPage from './AboutusPage'
import TermofservicePage from './TermofservicePage'
import DisclaimerPage from './DisclaimerPage'

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      modal: null,
    }
  }

  componentDidMount() {
    
  }

  closeModal(params) {
    this.setState({
      modal: null,
    })
  }
  
  openModal(value, params) {
    this.setState({
      modal: value,
    })
  }

  render() {
    const { modal }= this.state;
    const { screenHeight, screenWidth }= this.props;
    var path= this.props.path || 'home';

    // console.log('!!! ROUTE PROPS', this.props.routes['*'])
    if (this.props.routes['*']) {
      if (this.props.routes['*'].includes('about') || this.props.routes['*'].includes('about-us')) {
        path= 'about-us';
      } else if (this.props.routes['*'].includes('tos') || this.props.routes['*'].includes('term-of-service')) {
        path= 'term-of-service';
      } else if (this.props.routes['*'].includes('disclaimer')) {
        path= 'disclaimer';
      }
    }

    return(<div>
      {/* <div style={{height: '75px', background: '#f00'}} /> */}

      <HomeHeader screenWidth={screenWidth} path={path}/>
      
      {path=='home' && <ProductPage screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} modal={modal}  />}
      {path=='about-us' && <AboutusPage screenWidth={screenWidth} screenHeight={screenHeight}/> }
      {path=='term-of-service' && <TermofservicePage screenWidth={screenWidth} screenHeight={screenHeight}/> }
      {path=='disclaimer' && <DisclaimerPage screenWidth={screenWidth} screenHeight={screenHeight}/> }

      <HomeFooter screenWidth={screenWidth} />
    </div>)
  }
} 

// export default HomePage

export default (props) => (<HomePage {...props} routes={useParams()}/>);