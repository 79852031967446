import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
  Search,
  Popup,
} from 'semantic-ui-react'

import axios from 'axios';
import { useParams } from "react-router-dom"

const stylePopup = {
  borderRadius: '.3em 0',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '.8em',
  background: '#000',
  color: '#fff'
}

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      search: '',
    }
  }

  componentDidMount() {
    // console.log('!!! DID MOUNT ROUTE PROPS', this.props.routes);

    var scope= 'Stream! Outbound';
    
    if (this.props.routes && this.props.routes['module']) {
      if (this.props.routes['module'].includes('stream')) {
        scope= 'Stream! Outbound';
      } else if (this.props.routes['module'].includes('flow')) {
        scope= 'Flow! Omni-Channel';
      } else if (this.props.routes['module'].includes('talk')) {
        scope= 'Talk! In-App Voice';
      } else if (this.props.routes['module'].includes('ring')) {
        scope= 'Ring! Office Phone';
      }
    }
    
    axios.post('https://crm.halopagi.com/proxy/connect/api/v1/document/browse', {
      search: {
        scope: scope
      }
    })
    .then(resp=>{
      // console.log(resp.data);
      const { data }= resp.data;

      this.setState({
        index: data,
      })
    })
    .catch(function (error) {
      // console.log(error);
    });
  }

  componentDidUpdate() {
    // console.log('!!! DID UPDATE ROUTE PROPS', this.props.routes)
  }
  
  explodeIndex() {

    const { index, search }= this.state;
    return(
      <List ordered>
        {
          index && index.map((item, i)=>{
            if ((search=='' || (search!='' && item.keywords && item.keywords.indexOf(search.toLowerCase())>=0) || (item.title.toLowerCase().indexOf(search.toLowerCase())>=0)) && item.contents.length>0) {
              return(
                <List.Item style={{fontWeight: '600'}} key={i}>
                  <Popup style={stylePopup} content={item.title} position= 'right center' inverted
                    trigger={
                      <a alt={item.title} href={'/'+ item.title.replace(/[ /]/g, '-').replace(/[-]{2,3}/g, '-').replace(/[%:+,.'!*&"]/g, '').toLowerCase() +'/'+ item.documentId +'/doc/'+ (this.props.routes['module'] || 'stream-outbound')}>{item.title.length>20 ? item.title.substr(0,item.title.indexOf(' ', 15))+' ...' : item.title}</a>
                  } />
                  <List.List style={{fontWeight: '400', fontSize: '11pt'}}>
                    {item.contents && item.contents.map((subitem, j)=>{
                      return(
                        <Popup key={j} style={stylePopup} content={subitem.headline} position= 'right center' inverted
                          trigger={
                            <List.Item as='a'  alt={subitem.headline}  href={'/'+ item.title.replace(/[ /]/g, '-').replace(/[-]{2,3}/g, '-').replace(/[%:+,.'!*&"]/g, '').toLowerCase() +'/'+ item.documentId +'/doc/'+ (this.props.routes['module'] || 'stream-outbound') +'#'+ j} >{subitem.headline}</List.Item>
                        } />
                      )
                    })}
                  </List.List>
                </List.Item>
              )
            } else if (search=='' || (search!='' && item.keywords && item.keywords.indexOf(search)>=0) || (item.title.toLowerCase().indexOf(search.toLowerCase())>=0)) {
              return(
                <Popup key={i} style={stylePopup} content={item.title} position= 'right center' inverted
                  trigger={
                    <List.Item  alt={item.title} style={{fontWeight: '600'}} as='a' href={'/'+ item.title.replace(/[ /]/g, '-').replace(/[-]{2,3}/g, '-').replace(/[:+,.'"]/g, '').toLowerCase() +'/'+ item.documentId +'/doc/'+ (this.props.routes['module'] || 'stream-outbound')}>{item.title.length>20 ? item.title.substr(0,item.title.indexOf(' ', 15))+' ...' : item.title}</List.Item>
                } />
              )
            }
          })
        }
      </List>
    )
    
  }

  onSearch(e, v) {
    this.setState({
      search: v.value,
    })
  }

  render() {
    // console.log('!!! ROUTE PROPS', this.props.routes)
    const { screenHeight, screenWidth }= this.props
    
    return(
      <nav>
        <Container style={{padding: '6em 1.5em'}}>
          {/* <Header as='h5' icon style={{display: 'block'}}>
            <Icon name='rocket' />
            <Header.Content>
              API Connect
              <Header.Subheader>Integrasi menggunakan API.</Header.Subheader>
            </Header.Content>
          </Header> */}
          <div style={{top: (screenWidth<640 ? '0' : '4em'), width: (screenWidth<640 ? 'auto' : '195px'), position: (screenWidth<640 ? 'relative' : 'fixed'), zIndex: '9999999'}}>
            <Input fluid placeholder='Search...' onChange={this.onSearch.bind(this)}/>
          </div>
          <Divider hidden />
          {
            this.explodeIndex()
          }
          {/* <List ordered>
            <List.Item as='a'>Secret Key</List.Item>
            <List.Item as='a'>Campaign</List.Item>
            <List.Item>
              <a>Targetting</a>
              <List.List>
                <List.Item as='a'>HTML</List.Item>
                <List.Item as='a'>Javascript</List.Item>
                <List.Item as='a'>CSS</List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              <a>Agent</a>
              <List.List>
                <List.Item as='a'>HTML</List.Item>
                <List.Item as='a'>Javascript</List.Item>
                <List.Item as='a'>CSS</List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              <a>Reporting</a>
              <List.List>
                <List.Item as='a'>HTML</List.Item>
                <List.Item as='a'>Javascript</List.Item>
                <List.Item as='a'>CSS</List.Item>
              </List.List>
            </List.Item>
            <List.Item>
              <a>Webhook</a>
              <List.List>
                <List.Item as='a'>HTML</List.Item>
                <List.Item as='a'>Javascript</List.Item>
                <List.Item as='a'>CSS</List.Item>
              </List.List>
            </List.Item>
            <List.Item as='a'>Review</List.Item>
          </List> */}
        </Container>
      </nav>
    )
  }
} 

// export default IndexPage
export default (props) => (<IndexPage {...props} routes={useParams()}/>);
