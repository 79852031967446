import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import logo_protected from '../assets/logo-protected.png';
import logo_encrypted from '../assets/logo-encrypted.png';
import logo_saving from '../assets/logo-saving.png';
import logo_flexible from '../assets/logo-flexible.png';
import TalkAnimate from './TalkAnimate'

class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center', margin: '0'}}>
            <Header.Content>
              Pastikan <i><b>Talk!</b> In-App Voice</i>
              <div style={{fontWeight: '400', fontSize: '.8em'}}>Komunikasi <strong>Pelanggan</strong> dengan <strong>Driver</strong> / <strong>Merchant</strong> Jadi Aman!</div>
            </Header.Content>
          </Header>

          <div style={{textAlign: 'center',marginBottom: '2em'}}>
            {/* <Button size='big' color='blue' onClick={this.props.openModal.bind(this, 'contact', {service: 'talk'})}>Hubungi Sales!</Button> */}
            <Button as='a' size='big' color='green' href='/harga-terbaik-komunikasi-aman-pelanggan-mitra-merchant-untuk-unicorn-startup-marketplace-ride-sharing-online-delivery/pricing/talk-in-app-voice/'>Cek Harga!</Button>
            
            <Divider hidden />
            <TalkAnimate />
          </div>

          {/* <Divider horizontal>TENTANG DESK COLLECTION</Divider> */}
          <Container>
            <div style={{padding: screenWidth<1000 ? '1.5em' : '1.5em 10%', textAlign: 'center', marginBottom: '2em'}}>
              <p style={{fontSize: '1.5em'}}>
                Komunikasi Telepon dan Chat antara <b>Pelanggan</b> dengan <b>Mitra</b> (<em>Driver</em> / <em>Merchant</em>) dengan aman <strong>tanpa tahu nomor telepon</strong>, dan <strong>data pribadi</strong>, dan langsung diakses dari <cite>Mobile App</cite> Perusahaan Anda, hanya dengan paket data <strong>tanpa pulsa</strong><Label color='red' size='massive' style={{padding: '.2em .5em'}}>100% Hemat!</Label>.
              </p>
            </div>
          </Container>

          <div style={{background: '#e3f3f3', padding: screenWidth<1200 ? '1.5em 2em' : '1.5em 10%'}}>
            <Container>
              <Card.Group itemsPerRow={4} style={{padding: '3em 0 2em 0'}} stackable doubling>
                <Card>
                  <Image alt="Jaminan Keamanan" size='small' src={logo_protected} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Terlindungi
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                      Komunikasi yang aman dengan perlindungan data pribadi tanpa tahu nomor telepon dan informasi lainnya.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>
              
                <Card fluid>
                  <Image alt="Terenkripsi dan Terlindungi" src={logo_encrypted} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Enkripsi
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        Pilihan kanal yang bisa terenkripsi untuk menjamin privasi komunikasi antara <strong>Pelanggan</strong> dan <strong>Mitra</strong>.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>

                <Card>
                  <Image alt="Adaptif dan Fleksibel" size='small' src={logo_flexible} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Fleksibel
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        Diintegrasikan dengan <i>Mobile App</i> secara aman dan tidak boros baterai, terhubung jika diperlukan.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>

                <Card>
                  <Image alt="Hemat dengan Komunikasi Data" size='small' src={logo_saving} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Hemat <b>!</b>
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        100% Hemat <b>tanpa biaya pulsa</b>, hanya menggunakan paket data untuk komunikasi Suara dan Chat.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Container>
          </div>
          
        </Container>
      </main>
    )
  }
} 

export default ProductPage
