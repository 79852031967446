import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'


import ModalContainer from '../libraries/common/ModalContainer';
import PricingPage from './PricingPage';

import { ReactSVG } from 'react-svg'
import logo  from '../assets/logo.svg'

import HalopagiAnimate from './HalopagiAnimate';

class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      service: null,
    }
  }

  setService(v) {
    if (v==this.state.service) {
      this.setState({
        service: null
      })
    } else {
      this.setState({
        service: v
      })
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <div style={{textAlign: 'center', width: '9em', height: '9em', margin: '0 auto'}}>
            {/* <Image alt="Halo Pagi! Communication Platform" src={'https://halopagi.com/asset/logo.png'} size='small' circular verticalAlign='middle' /> */}
            <ReactSVG src={logo}/>
          </div>

          <Header as='h1' style={{display: 'block', textAlign: 'center', margin: '0'}}>
            <Header.Content style={{fontSize: '1.2em'}}>
              <strong>Platform <b>Komunikasi</b> 
              <br />Untuk Semua Kebutuhan Perusahaan Anda</strong>
              {/* <Header.Subheader style={{padding: '0 15%'}} >
                Komunikasi berbasis data sangat menguntungkan, dari faktor biaya operasional, kemudahan dan fleksibilitas layanan sebagai fitur aplikasi-aplikasi internal perusahaan. Kini hadir layanan komunikasi untuk kebutuhan perusahaan Anda, baik untuk <i>outbound campaign</i>, <i>omni-channel</i>, <i>in-app voice</i> dan <i>private office phone</i>.
              </Header.Subheader> */}
            </Header.Content>
          </Header>
          <p style={{marginTop: '-2.5em', fontSize: '1.3em', padding: '1em 15%', textAlign: 'center'}}>
            Layanan komunikasi untuk kebutuhan perusahaan Anda berbasis data, <strong>lebih 100% Hemat</strong> dan <strong>mudah diintegrasikan</strong>, baik untuk <i>Outbound Campaign</i>, <i>Omni-Channel</i>, <i>In-App Voice</i> dan <i>Private Office Phone</i>.
          </p>

          <div style={{textAlign: 'center',marginBottom: '6em'}}>
            <span style={{background: '#f5f2e0', padding: '1.8em 1em', borderRadius: '.5em'}}>
              <Button size='big' style={{margin: '0'}} color='blue' onClick={this.props.openModal.bind(this, 'pricing')}>Cek Harga !</Button>
            </span>
            
            <Divider hidden />
            <HalopagiAnimate screenHeight={screenHeight} screenWidth= {screenWidth} />
            
            <Divider />
            <div style={{margin: '2em 0'}}>
              <List horizontal>
                <List.Item as='a' onClick={this.setService.bind(this, 'stream')}>
                  {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/tom.jpg' /> */}
                  <Icon name='send' color={(this.state.service=='stream' ? 'blue' : null)} />
                  <List.Content>
                    <b>Stream!</b>
                    <List.Header>Outbound</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item as='a' onClick={this.setService.bind(this, 'flow')}>
                  {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/christian.jpg' /> */}
                  <Icon name='comments' color={(this.state.service=='flow' ? 'blue' : null)} />
                  <List.Content>
                    <b>Flow!</b>
                    <List.Header>Omni-Channel</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item as='a' onClick={this.setService.bind(this, 'talk')}>
                  {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' /> */}
                  <Icon name='microphone' color={(this.state.service=='talk' ? 'blue' : null)} />
                  <List.Content>
                    <b>Talk!</b>
                    <List.Header>In App Voice</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item as='a' onClick={this.setService.bind(this, 'ring')}>
                  {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' /> */}
                  <Icon name='phone' color={(this.state.service=='ring' ? 'blue' : null)} />
                  <List.Content>
                    <b>Ring!</b>
                    <List.Header>Office Phone</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </div>
          </div>
          

          {(this.state.service==null || this.state.service=='stream') && 
          <div className={(this.state.service=='stream' && 'animfadeout') || ''} style={{overflow: 'hidden', margin: '4em auto 0 auto', textAlign: 'center'}}>
            <div style={{height: '450px', margin: '0', background: '#fff url(https://halopagi.com/asset/bg-stream.png) no-repeat center'}} />
            <div style={{marginTop: '-450px', minHeight: '450px', background: 'rgba(237, 237, 222, .3)'}}>
              <div style={{background: 'rgba(250, 250, 245, .9)', padding: screenWidth<1000 ? '1.5em' : '1.5em 15%', textAlign: 'left'}}>
                <Header as='h2' style={{display: 'block', margin: '0'}}>
                  <Header.Content>
                    <Icon name='long arrow alternate right' />
                    <strong><b>Stream!</b> Outbound</strong>
                    {/* <Header.Subheader>Pastikan Pelanggan telah meneriman pesan Anda.</Header.Subheader> */}
                  </Header.Content>
                </Header>
                <p style={{fontSize: '1.2em'}}>
                  Kebutuhan komunikasi berbasis <i>outbound campaign</i> secara otomatis dan masif untuk setiap perusahaan, untuk  <b>Telesales</b>, <b>Verification</b>, <b>Survey</b> atau <b>Collection</b>.
                </p>
                {false && <p style={{fontSize: '1.2em'}}>
                  Dengan <b>Stream!</b> Outbound memastikan bahwa proses penyampaian informasi akan diterima di sisi <b>Pelanggan</b>, dengan pengiriman <i>predictive</i> / otomatis atau <i>preview</i> / manual secara masif dan pilihan kanal <i>multi-channel</i>, <b>Telepon</b>, <b>IVR / Robo Call</b>, <b>SMS</b>, <b>Email</b>, <b>Whatsapp</b>  dan <b>Social Media</b>, sehingga bilamana kanal telepon gagal akan dikirimkan melalui email.
                </p>}

                <Divider horizontal>
                  <Label color='green' as='a' href='/multi-channel-outbound-campaign-dengan-fitur-terbaik-dan-harga-murah/stream-outbound/'>
                    Pelajari lebih lanjut <Icon name='arrow right' />
                  </Label>
                </Divider>
              </div>
            </div>
          </div>}

          {(this.state.service==null || this.state.service=='flow') && 
          <div className={(this.state.service=='flow' && 'animfadeout') || ''} style={{overflow: 'hidden', margin: '4em auto 0 auto', textAlign: 'center'}}>
            <div style={{height: '450px', margin: '0', background: '#fff url(https://halopagi.com/asset/bg-flow.png) no-repeat center'}} />
            
            <div style={{marginTop: '-450px', minHeight: '450px', background: 'rgba(240, 222, 222, .3)'}}>
              <div style={{background: 'rgba(250, 245, 245, .8)', padding: screenWidth<1000 ? '1.5em' : '1.5em 15%', textAlign: 'left'}}>
                <Header as='h2' style={{display: 'block', margin: '0'}}>
                  <Header.Content>
                    <Icon name='long arrow alternate right' />
                    <strong><b>Flow!</b> Omni-Channel</strong>
                    {/* <Header.Subheader>Karena <i>conversation</i> bisa dimulai darimana saja!.</Header.Subheader> */}
                  </Header.Content>
                </Header>
                <p style={{fontSize: '1.2em'}}>
                  Pengalaman yang sama untuk setiap interaksi <strong>Pelanggan</strong>, dengan <b>Telepon</b>, <b>Online Chat</b>, <b>SMS</b>, <b>Whatsapp</b>, <b>Email</b>, <b>Facebook</b>, <b>Instagram</b> dan <b>Twitter</b>.
                </p>
                {/* <p style={{fontSize: '1.2em'}}>
                  Dengan aplikasi <b>Flow!</b> Omni-Channel pengalaman komunikasi dengan <b>Pelanggan</b> akan menjadi lebih seru dan terintegrasi satu pintu yang mempermudah setiap Agent untuk berinteraksi dengan lebih baik, untuk meningkatkan konversi peluang menjadi revenue.
                </p> */}
                <Divider horizontal>
                  <Label color='green' as='a' href='/omni-channel-dengan-fitur-terbaik-dan-harga-murah/flow-omni-channel/'>
                    Pelajari lebih lanjut <Icon name='arrow right' />
                  </Label>
                </Divider>
              </div>
            </div>
          </div>}

          {(this.state.service==null || this.state.service=='talk') && 
          <div className={(this.state.service=='talk' && 'animfadeout') || ''} style={{overflow: 'hidden', margin: '4em auto 0 auto', textAlign: 'center'}}>
            <div style={{height: '450px', margin: '0', background: '#fff url(https://halopagi.com/asset/bg-talk.png) no-repeat center'}} />
              
            <div style={{marginTop: '-450px', minHeight: '450px', background: 'rgba(237, 237, 222, .3)'}}>
              <div style={{background: 'rgba(250, 250, 245, 92%)', padding: screenWidth<1000 ? '1.5em' : '1.5em 15%', textAlign: 'left'}}>
                <Header as='h2' style={{display: 'block', margin: '0'}}>
                  <Header.Content>
                    <Icon name='long arrow alternate right' />
                    <strong><b>Talk!</b> In-App Voice</strong>
                  </Header.Content>
                </Header>
                <p style={{fontSize: '1.2em'}}>
                Komunikasi <strong>suara</strong> dan <strong>chat</strong> antara <b>Mitra</b> dan <b>Pelanggan</b> tanpa perlu tahu nomor telepon dan data pribadi Pelanggan  .
                </p>
                {/* <p style={{fontSize: '1.2em'}}>
                  Komunikasi telepon untuk <i>marketplace</i>, <i>ride sharing</i>, <i>online delivery</i> antara <b>Mitra</b> dan <b>Pelanggan</b> tanpa perlu tahu nomor telepon, tanpa perlu tahu identitas pribadi, kini bisa dilakukan secara langsung dengan aman dan nyaman, melalui <i>platform</i> atau <i>mobile app</i> Anda sebagai fitur baru!.
                </p> */}
                {/* <p style={{fontSize: '1.2em'}}>
                  Dapat Anda bayangkan fitur <i>mobile app</i> yang ada di <i>unicorn</i> kini bisa dinikmati secara luas, bahkan dengan opsi komunikasi terenkripsi untuk memastikan lebih aman untuk interaksi yang diperlukan privasi antara <b>Mitra</b> dan <b>Pelanggan</b>.
                </p> */}
                <Divider horizontal>
                  <Label color='green' as='a' href='/komunikasi-aman-pelanggan-mitra-merchant-untuk-marketplace-ride-sharing-online-delivery/talk-in-app-voice/'>
                    Pelajari lebih lanjut <Icon name='arrow right' />
                  </Label>
                </Divider>
              </div>
            </div>
          </div>}
          
          {(this.state.service==null || this.state.service=='ring') && 
          <div className={(this.state.service=='ring' && 'animfadeout') || ''} style={{overflow: 'hidden', margin: '4em auto 0 auto', textAlign: 'center'}}>
            <div style={{height: '450px', margin: '0', background: '#fff url(https://halopagi.com/asset/bg-ring.png) no-repeat center'}} />
            
            <div style={{marginTop: '-450px', minHeight: '450px', background: 'rgba(240, 222, 222, .3)'}}>
              <div style={{background: 'rgba(250, 245, 245, .8)', padding: screenWidth<1000 ? '1.5em' : '1.5em 15%', textAlign: 'left'}}>
                <Header as='h2' style={{display: 'block', margin: '0'}}>
                  <Header.Content>
                    <Icon name='long arrow alternate right' />
                    <strong><b>Ring!</b> Office Phone</strong>
                  </Header.Content>
                </Header>
                <p style={{fontSize: '1.2em'}}>
                  Kolaborasi semua cabang perusahaan dengan komunikasi <strong>video</strong>, <strong>suara</strong> dan <strong>chat</strong> berbasis data!, <b>100% Hemat !</b>.
                </p>
                {/* <p style={{fontSize: '1.2em'}}>
                  Untuk perusahaan yang tersebar di banyak kota yang secara geografis berjauhan, seringkali memerlukan komunikasi antar kantor cabang.  Dengan solusi layanan <b>Ring!</b> Office Phone dapat dilakukan secara mudah dan murah karena komunikasi berbasis voip / data.
                </p>
                <p style={{fontSize: '1.2em'}}>
                  Dengan layanan ini aplikasi <b>kolaborasi</b> perusahaan juga dapat mempunyai fitur komunikasi secara langsung antar pegawai, tanpa harus tahu nomor telepon, tanpa aplikasi <i>chat/call</i> lainnya, baik untuk <i>peer to peer</i> atau <i>conference - multi user</i>.
                </p> */}
                <Divider horizontal>
                  <Label color='green' as='a' href='/kolaborasi-tanpa-batas-perusahaan-dengan-fitur-conference-call-ivr-auto-recording/ring-office-phone/'>
                    Pelajari lebih lanjut <Icon name='arrow right' />
                  </Label>
                </Divider>
              </div>
            </div>
          </div>}

        </Container>

        {this.props.modal==='pricing' && <ModalContainer size='small' content={<PricingPage screenWidth={screenWidth} screenHeight={screenHeight} closeModal={this.props.closeModal.bind(this)} />} closeModal={this.props.closeModal.bind(this)} /> }
      </main>
    )
  }
} 

export default ProductPage
