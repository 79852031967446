import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import { useParams } from "react-router-dom"

import DocHeader from './DocHeader'
import DocFooter from './DocFooter'

import IndexPage from './IndexPage'
import ContentPage from './ContentPage'

class DocPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      fullScreen: false,
    }
  }

  componentDidMount() {
    
  }

  maximize() {
    this.setState({
      fullScreen: !this.state.fullScreen,
    })
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    var path= 'stream';

    if (this.props.path) {
      path= this.props.path;
    } else if (this.props.routes && this.props.routes['module']=='stream-outbound') {
      path= 'stream';
    } else if (this.props.routes && this.props.routes['module']=='flow-omni-channel') {
      path= 'flow';
    } else if (this.props.routes && this.props.routes['module']=='talk-in-app-voice') {
      path= 'talk';
    } else if (this.props.routes && this.props.routes['module']=='ring-office-phone') {
      path= 'ring';
    }
    
    return(<div>
      {!this.state.fullScreen && <DocHeader screenWidth={screenWidth} path={path} />}
      
      <div style={{display: 'flex', minHeight: screenHeight}}>
        {!this.state.fullScreen && <div style={{position: (screenWidth<640 ? 'fixed' : 'relative'), height: screenHeight, zIndex: '99', overflow: 'scroll', width: '250px', background: '#f8faf0'}}>
          <IndexPage screenWidth={screenWidth} screenHeight={screenHeight} />
        </div>}
        <div style={{flex:'1'}}>
          <ContentPage screenWidth={screenWidth} screenHeight={screenHeight} fullScreen={this.state.fullScreen} maximize={this.maximize.bind(this)}/>
        </div>
      </div>
    </div>)
  }
} 

// export default DocPage
export default (props) => (<DocPage {...props} routes={useParams()}/>);