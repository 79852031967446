import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{padding: '2em 1.5em', background: '#fff'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '0 0'}}>
              <Header as='h2' style={{display: 'block', textAlign: 'center'}}>
                <Header.Content>
                  <strong>Harga Layanan</strong>
                  <Header.Subheader><strong>Terjangkau Dengan Segala Kebaikannya.</strong></Header.Subheader>
                </Header.Content>
              </Header>

              <Divider />

              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    {screenWidth>600 &&
                      <Table.Cell rowSpan={2}>
                        <Icon name='send' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                      </Table.Cell>
                    }
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <strong><b>Stream!</b> Outbound</strong>
                        </Header.Content>
                      </Header>
                      <p>
                        Layanan <strong>Telesales</strong>, <strong>Survey</strong>, <strong>Verification</strong>, <strong>Collection</strong> dengan fitur manual/preview dan predictive call dan broadcast message.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={8} style={{background: '#fafafa'}}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>650K</strong> / User
                          <Header.Subheader>MULAI DARI HARGA TERBAIK <b>!</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Label color='red' as='a' href='/harga-layanan/pricing/stream-outbound/' style={{padding: '1.5em 1em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label>
                    </Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>650K</strong> / User
                          <Header.Subheader><strong>MULAI DARI HARGA TERBAIK <b>!</b></strong> <Label color='red' as='a' href='/harga-layanan/stream-outbound/' style={{padding: '.4em .5em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row> */}
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.85em'}}>
                        <List.Item as='li' value='*'>
                          Biaya yang dibebankan oleh pihak provider atau pihak ketiga lainnya merupakan biaya terpisah seperti Voice, SMS, Whatsapp dan lainnya.
                        </List.Item>
                        <List.Item as='li' value='*'>
                          Tersedia pilihan untuk layanan Cloud, On Premise dengan paket lisensi User/Seat, CPU / Server dan Pay Per Use.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row> */}
                </Table.Body>
              </Table>

              <Divider />

              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    {screenWidth>600 &&
                      <Table.Cell rowSpan={2}>
                        <Icon name='comments' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                      </Table.Cell>
                    }
                    <Table.Cell width={8} style={{background: '#fafafa'}}>
                      <Header as='h3'>
                        <Header.Content>
                          <strong><b>Flow!</b> Omni-Channel</strong>
                        </Header.Content>
                      </Header>
                      <p>
                        Interaksi dari kanal apa saja, meningkatkan <i>engagement</i> dan <i>conversion</i>, Chat, Voip, Telepon, SMS, Email, Whatsapp dan Socmed.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={8}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>450K</strong> / User
                          <Header.Subheader>MULAI DARI HARGA TERBAIK <b>!</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Label color='red' as='a' href='/harga-layanan/pricing/flow-omni-channel/' style={{padding: '1.5em 1em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label>
                    </Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>450K</strong> / User
                          <Header.Subheader><strong>MULAI DARI HARGA TERBAIK <b>!</b></strong> <Label color='red' as='a' href='/harga-layanan/flow-omni-channel/' style={{padding: '.4em .5em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row> */}
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.85em'}}>
                        <List.Item as='li' value='*'>
                          Biaya yang dibebankan oleh pihak provider atau pihak ketiga lainnya merupakan biaya terpisah seperti Voice, SMS, Whatsapp dan lainnya.
                        </List.Item>
                        <List.Item as='li' value='*'>
                          Tersedia pilihan untuk layanan Cloud, On Premise dengan paket lisensi User/Seat, CPU / Server dan Pay Per Use.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row> */}
                </Table.Body>
              </Table>

              <Divider />

              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    {screenWidth>600 &&
                      <Table.Cell rowSpan={2}>
                        <Icon name='microphone' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                      </Table.Cell>
                    }
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <strong><b>Talk!</b> In-App Voice</strong>
                        </Header.Content>
                      </Header>
                      <p>
                        Komunikasi <strong> aman </strong> tanpa lihat nomor telepon dan data pribadi antara <strong>Pelanggan</strong> dan <strong>Mitra</strong>.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={8} style={{background: '#fafafa'}}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>200K</strong> / Line
                          <Header.Subheader>MULAI DARI HARGA TERBAIK <b>!</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Label color='red' as='a' href='/harga-layanan/pricing/talk-in-app-voice/' style={{padding: '1.5em 1em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label>
                    </Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>200K</strong> / Line
                          <Header.Subheader><strong>MULAI DARI HARGA TERBAIK <b>!</b></strong> <Label color='red' as='a' href='/harga-layanan/talk-in-app-voice/' style={{padding: '.4em .5em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row> */}
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.85em'}}>
                        <List.Item as='li' value='*'>
                          Belum termasuk biaya integrasi dan kustomisasi communication experience.
                        </List.Item>
                        <List.Item as='li' value='*'>
                          Tersedia pilihan untuk layanan Cloud, On Premise dengan paket lisensi Line, CPU / Server dan Pay Per Use.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row> */}
                </Table.Body>
              </Table>

              <Divider />
              <Table basic='very' columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    {screenWidth>600 &&
                      <Table.Cell rowSpan={2}>
                        <Icon name='phone' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                      </Table.Cell>
                    }
                    <Table.Cell width={8} style={{background: '#fafafa'}}>
                      <Header as='h3'>
                        <Header.Content>
                          <strong><b>Ring!</b> Office Phone</strong>
                        </Header.Content>
                      </Header>
                      <p>
                        Kolaborasi tanpa batas seluruh sumber daya <strong>Perusahaan</strong> di mana saja, kapan saja.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={8}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>25K</strong> / EXT
                          <Header.Subheader>MULAI DARI HARGA TERBAIK <b>!</b></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Label color='red' as='a' href='/harga-layanan/pricing/ring-office-phone/' style={{padding: '1.5em 1em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label>
                    </Table.Cell>
                  </Table.Row>
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <Header as='h3' style={{display: 'block', textAlign: 'center'}} color='blue'>
                        <Header.Content>
                          <sup>Rp.</sup> <strong style={{fontSize: '1.3em'}}>25K</strong> / EXT
                          <Header.Subheader><strong>MULAI DARI HARGA TERBAIK <b>!</b></strong> <Label color='red' as='a' href='/harga-layanan/ring-office-phone/' style={{padding: '.4em .5em'}} ><Icon style={{margin: '0'}} name='long arrow alternate right' /></Label></Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                  </Table.Row> */}
                  {/* <Table.Row>
                    <Table.Cell width={15}>
                      <List as='ol' style={{color: '#333', fontSize: '.85em'}}>
                        <List.Item as='li' value='*'>
                          Belum termasuk biaya perangkat Desk Phone.
                        </List.Item>
                        <List.Item as='li' value='*'>
                          Belum termasuk biaya instalasi, integrasi dan kustomisasi communication experience.
                        </List.Item>
                        <List.Item as='li' value='*'>
                          Tersedia pilihan untuk layanan Cloud, On Premise dengan paket lisensi Line, CPU / Server dan Pay Per Use.
                        </List.Item>
                      </List>
                    </Table.Cell>
                  </Table.Row> */}
                </Table.Body>
              </Table>
            </div>
          </div>
        </Container>
      </main>
    )
  }
} 

export default PricingPage
