import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class FeaturePage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              Fitur Terbaik dari <br />Platform <em><b>Talk!</b> <strong>In-App Voice</strong></em> <br />
              <Header.Subheader style={{padding: '0 15%'}} >
                Pengalaman yang sama berinteraksi dengan Pelanggan yang datang dari kanal apapun, untuk meningkatkan engagement dan conversion.
              </Header.Subheader>
            </Header.Content>
          </Header>

          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>EKOSISTEM</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 6em 0'}}>
            <Grid.Row>
              <Grid.Column width={6} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h2'>
                  <Header.Content>
                    <i>Widget</i> Terintegrasi
                    <Header.Subheader><i>Widget</i> terintegrasi menyatu dengan aplikasi.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Widget menyatu dengan aplikasi yang bisa ditempelkan, baik untuk interaksi berbasis Voice, Messaging atau Social Media.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Pilihan Kanal Pengiriman
                    <Header.Subheader>Pastikan pesan Anda terkirim dengan fitur <i>multi-channel</i>.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Dengan pilihan kanal pengiriman berdasarkan ketersediaan Pelanggan yang dapat dipilih untuk memastikan engagement menjadi lebih menarik.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Satu Pengalaman Berinteraksi
                    <Header.Subheader>Interaksi dengan dengan kanal apapun dengan pengalaman yang sama.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Lakukan interaksi dengna Pelanggan dalam satu pengalaman yang sama dengan kanal komunikasi yang berbeda-beda.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>PERFORMANSI</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 6em 0'}}>
            <Grid.Row>
              <Grid.Column width={6} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h2'>
                  <Header.Content>
                    Jaminan Performansi
                    <Header.Subheader><i>Perform by Default</i>, ekosistem yang mendukung performansi tinggi.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Aplikasi memastikan bahwa setiap  <i>Agent</i> akan mendapatkan performansi terbaik di setiap aktivitasnya. Dengan fitur yang secara sistematis akan meningkatkan tingkat okupansi  <i>Agent</i> dan mengukur waktu interaksi dengan Pelanggan.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Otomatis <i>Push Dispatch</i>
                    <Header.Subheader>Menerima panggilan tanpa mengangkat telepon.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap  <i>Agent</i> yang telah online akan menerima alokasi Pelanggan secara otomatis untuk setiap interaksi yang telah diterima.
                </p>
                <Header as='h2'>
                  <Header.Content>
                    Alokasi Target dengan  <i>Agent</i>  
                    <Header.Subheader>Mekanisme alokasi target dengan konsep <i>locking </i> dan <i>floating</i>.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap target yang dimasukkan dalam sistem, dapat dialokasikan ke  <i>Agent</i> tertentu atau ke semua  <i>Agent</i> secara bebas, atau mekanisme <i>locking </i> dan <i>floating</i>.  Konsep ini diimpelementasikan dengan <i>workgrouping</i>, <i>tagger</i> dan alokasi <i>campaign</i>.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        
          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>PROGRESSIVE</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 6em 0'}}>
            <Grid.Row>
              <Grid.Column width={6} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h2'>
                  <Header.Content>
                    <i>Feedback</i> Berbasis Pencapaian
                    <Header.Subheader>Progress <i>feedback</i> dilakukan dengan skala pencapaian <b>0 - 100%</b>.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap <i>feedback</i>  <b>Pelanggan</b> akan dicatat dalam sistem, baik yang diinput  <i>Agent</i> atau oleh sistem bilamana dideteksi secara otomatis. Dengan menggunakan skala <b>0 - 100%</b> untuk setiap jenis <i>feedback</i>, sehingga yang mencapai <b>100%</b> maka  <b>Pelanggan</b> dinyatakan telah selesai. 
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Segmentasi Respons  <i>Feedback</i>
                    <Header.Subheader>Reject, Ignored,Dropped by System, Invalid, Mailbox, Reschedule, Promised dan Paid.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Respons setiap panggilan dibagi menjadi dua jenis yang ditentukan oleh  <i>Agent</i> sebagai <i>feedback</i> dan ditentukan oleh sistem secara otomatis.  Setiap respons tersebut akan didefinisikan skala pencapaian dari <b>0 - 100%</b>, segmentasi response untuk setiap Pelanggan yang akan disimpan dalam proses di bagian terkait seperti CRM atau Ticketing.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>CRM & TICKETING</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 6em 0'}}>
            <Grid.Row>
              <Grid.Column width={6} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h2'>
                  <Header.Content>
                    Terintegrasi dengan CRM
                    <Header.Subheader><i>Perform by Default</i>, ekosistem yang mendukung performansi tinggi.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap data feedback hasil interaksi dengan Pelanggan dapat disimpan dalam CRM secara otomatis sesuai dengan keperluan.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Terintegrasi dengan Ticketing
                    <Header.Subheader>Menerima panggilan tanpa mengangkat telepon.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Data hasil interaksi dengan Pelanggan yang berkaitan dengan Ticketing akan disimpan dalam sistem Ticketing untuk setiap progress ticket yang telah dilakukan.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>MONITORING</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 0em 0'}}>
            <Grid.Row>
              <Grid.Column width={6} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as='h2'>
                  <Header.Content>
                    <i>Real Time</i> Rekaman Audio
                    <Header.Subheader>Rekam pembicaraan dan langsung bisa dengarkan online saat selesai panggilan.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Rekaman audio panggilan kini dapat didengarkan saat itu juga ketika panggilan selesai, tinggal <cite>click</cite> dan <cite>play</cite> melalui aplikasi secara online, tanpa diunduh dan tanpa pemutar tertentu.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    <i>Dashboard</i> Kontrol & Monitoring
                    <Header.Subheader><i>Real time</i>  <i>Agent</i>, <i>Campaign</i> dan <i>System</i> Monitoring.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap aktivitas yang berkaitan dengan  <i>Agent</i>,  <i>Campaign </i> dan <i>System</i> disimpan dan ditampilkan oleh aplikasi dalam bentuk  <i>dashboard</i> yang mudah dimengerti dan dibaca oleh Admin atau pun  <i>Agent</i>.  Sehingga kondisi dan pencapaian dari setiap aktivitas termonitor dengan baik.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </main>
    )
  }
} 

export default FeaturePage
