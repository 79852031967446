import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class TermofservicePage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{minHeight: screenHeight, padding: '6em 2em', background: '#fff'}}>
          <div style={{margin: '0 auto', maxWidth: '700px', textAlign: 'justify'}}>
            <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
              <Header.Content>
                <strong>Ketentuan Penggunaan Layanan</strong>
                {/* <div style={{fontSize: '.8em'}}><b>Stream!</b> Outbound, <b>Flow!</b> Omni-Channel, <b>Talk!</b> In-App Voice dan <b>Ring!</b> Office Phone</div> */}
                <div style={{fontSize: '.8em'}}>Berlaku untuk keseluruhan layanan, kecuali ditentukan khusus secara terpisah.</div>
              </Header.Content>
            </Header>
          
            <div style={{margin: '3em auto'}}>
              <Header as='h2'>
                <Header.Content>
                  <strong>1. Jenis Konten</strong>
                  {/* <Header.Subheader>Jenis konten yang dikirimkan.</Header.Subheader> */}
                </Header.Content>
              </Header>
              <p>
                Konten yang dikirimkan tidak boleh bertentangan dengan Perundang-undangan Republik Indonesia. <strong>Perusahaan</strong> pengguna layanan tidak boleh mengirimkan konten yang termasuk dalam kategori Pornografi, Judi, Kriminal, Ancaman dan Pelecehan.
              </p>
              <p>
                Konten yang tidak sesuai  dengan peraturan Perundang-undangan Republik Indonesia dapat dibatalkan dan dihapus secara sepihak oleh <strong>Penyedia</strong> layanan.
              </p>
            </div>

            <div style={{margin: '3em auto'}}>
              <Header as='h2'>
                <Header.Content>
                  <strong>2. Kontak Target</strong>
                </Header.Content>
              </Header>
              <p>
                Data kontak target yang akan dikirimkan konten merupakan <strong>Pelanggan</strong> dari <strong>Perusahaan</strong> pengguna layanan, kontak target dapat melakukan penutupan akses oleh <strong>Perusahaan</strong> pengguna layanan dengan cara meminta langsung ke <strong>Penyedia</strong> layanan.
              </p>
              <p>
                <strong>Penyedia</strong> dapat memabatalkan proses pengiriman konten secara sepihak bilamana kontak target tidak sesuai dan bukan merupakan <strong>Pelanggan</strong> dari <strong>Perusahaan</strong> pengguna layanan.
              </p>
            </div>

            <div style={{margin: '3em auto'}}>
              <Header as='h2'>
                <Header.Content>
                  <strong>3. Waktu Pengiriman</strong>
                </Header.Content>
              </Header>
              <p>
                <strong>Penyedia</strong> layanan menentukan bahwa untuk pengiriman konten kepada kontak target hanya dapat dilakukan dari jam 06:00 s.d. 21:00, untuk semua media pengiriman yang didukung oleh <strong>Penyedia</strong> layanan.
              </p>
              <p>
                Pengiriman konten di luar waktu yang disebutkan di atas oleh <strong>Perusahaan</strong> pengguna layanan, secara sepihak <strong>Penyedia</strong> layanan dapat memutuskan dan menunda hingga hari berikutnya untuk memastikan kontak target tidak terganggu.
              </p>
            </div>

            <div style={{margin: '3em auto'}}>
              <Header as='h2'>
                <Header.Content>
                <strong>4. Analisis Tingkah Laku</strong>
                </Header.Content>
              </Header>
              <p>
                Untuk setiap target data kontak dan proses pengiriman konten sistem akan melakukan analisa tingkah laku untuk memastikan waktu terbaik pengiriman konten, sehingga tingkat penerimaan di kontak target diperoleh secara maksimal.
              </p>
              <p>
                Data hasil analisa tersebut tidak diperjualbelikan kepada pihak-pihak eksternal dan hanya digunakan untuk keperluan internal <strong>Penyedia</strong> layanan.
              </p>
            </div>
          </div>

        </Container>
      </main>
    )
  }
} 

export default TermofservicePage
