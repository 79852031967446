import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class FeaturePage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              Fitur Terbaik dari <br />Platform <b>Stream!</b> <strong>Outbound</strong> <br />
              <Header.Subheader style={{padding: '0 15%'}} >
                Satu aplikasi untuk Desk <cite>Collection</cite>, <cite>Telesales</cite>, <cite>Survey</cite> dan <cite>Verification</cite> kini hadir agar  <i>Agent</i> menghasilkan performansi puncak mereka dengan dukungan fitur-fitur penting dan tentunya terbaik.
              </Header.Subheader>
            </Header.Content>
          </Header>

          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>EKOSISTEM</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 6em 0'}}>
            <Grid.Row>
              <Grid.Column width={10} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h2'>
                  <Header.Content>
                    <i>Softphone</i> Terintegrasi
                    <Header.Subheader><i>Softphone</i> terintegrasi menyatu dengan aplikasi.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  <i>Softphone</i> kini dalam satu paket dengan aplikasi, sehingga  <i>Agent</i> hanya mengingat satu akun baik untuk Aplikasi dan Voip. Ketika <i>Sign In</i>,  <i>Agent</i> dapat langsung terdeteksi apakah sudah online untuk menerima panggilan atau belum.  Dengan demikian <i>softphone</i> lainnya berbasis desktop tidak perlu diinstall dan digunakan lagi.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Pilihan Kanal Pengiriman
                    <Header.Subheader>Pastikan pesan Anda terkirim dengan fitur <i>multi-channel</i>.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Apakah yang terjadi jika telepon ke  <b>Pelanggan</b> tidak diangkat hingga batas dial tertentu ?, hal ini menjadi pertanyaan ketika melakukan <i>outbound campaign</i>.  Untuk itulah diperlukan adanya alternatif kanal yang menjadi pilihan kedua ketika kanal utama tidak diangkat, dengan mengirim pesan <i>SMS</i>, <i>Whatsapp</i> atau <i>Email</i> untuk memastikan bahwa informasi akan sampai ke  <b>Pelanggan</b>.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    <i>Preview </i> & <i>Predictive</i> Call
                    <Header.Subheader>Manual dial dan auto dial dalam satu aplikasi.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Pilihan untuk melakukan <i>preview </i> dial atau <i>predictive</i> dial dapat dilakukan dalam satu pengalaman aplikasi yang sama. Sehingga hal ini mempermudah dan akan tetap menjamin performansi  <i>Agent</i> dikarenakan setelah mode <i>predial</i> selesai, aplikasi secara otomatis akan berubah ke <i>predictive</i>.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          
          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>PERFORMANSI</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 6em 0'}}>
            <Grid.Row>
              <Grid.Column width={10} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h2'>
                  <Header.Content>
                    Jaminan Performansi
                    <Header.Subheader><i>Perform by Default</i>, ekosistem yang mendukung performansi tinggi.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Aplikasi memastikan bahwa setiap  <i>Agent</i> akan mendapatkan performansi terbaik di setiap aktivitasnya. Dengan fitur yang secara sistematis akan meningkatkan tingkat okupansi  <i>Agent</i> dan mengukur waktu bicara dalam setiap panggilan.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Otomatis <i>Call Dispatch</i>
                    <Header.Subheader>Menerima panggilan tanpa mengangkat telepon.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap  <i>Agent</i> yang telah online akan menerima panggilan secara otomatis untuk setiap panggilan yang telah diterima oleh  <b>Pelanggan</b>. Tanpa menekan tombol atau mengangkat telepon, hal ini untuk memastikan bahwa setiap  <i>Agent</i> akan menerima distribusi load yang fair.
                </p>
                <Header as='h2'>
                  <Header.Content>
                    Alokasi Target dengan  <i>Agent</i>  
                    <Header.Subheader>Mekanisme alokasi target dengan konsep <i>locking </i> dan <i>floating</i>.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap target yang dimasukkan dalam sistem, dapat dialokasikan ke  <i>Agent</i> tertentu atau ke semua  <i>Agent</i> secara bebas, atau mekanisme <i>locking </i> dan <i>floating</i>.  Konsep ini diimpelementasikan dengan <i>workgrouping</i>, <i>tagger</i> dan alokasi <i>campaign</i>.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        
          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>PROGRESSIVE</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 6em 0'}}>
            <Grid.Row>
              <Grid.Column width={10} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h2'>
                  <Header.Content>
                    <i>Feedback</i> Berbasis Pencapaian
                    <Header.Subheader>Progress <i>feedback</i> dilakukan dengan skala pencapaian <b>0 - 100%</b>.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap <i>feedback</i>  <b>Pelanggan</b> akan dicatat dalam sistem, baik yang diinput  <i>Agent</i> atau oleh sistem bilamana dideteksi secara otomatis. Dengan menggunakan skala <b>0 - 100%</b> untuk setiap jenis <i>feedback</i>, sehingga yang mencapai <b>100%</b> maka  <b>Pelanggan</b> dinyatakan telah lengkap dan tidak akan dikontak lagi. 
                </p>

                <Header as='h2'>
                  <Header.Content>
                    Segmentasi Respons  <i>Feedback</i>
                    <Header.Subheader>Reject, Ignored,Dropped by System, Invalid, Mailbox, Reschedule, Promised dan Paid.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Umumnya respons setiap panggilan dibagi menjadi dua jenis yang ditentukan oleh  <i>Agent</i> sebagai <i>feedback</i> dan ditentukan oleh sistem secara otomatis.  Setiap respons tersebut akan didefinisikan skala pencapaian dari <b>0 - 100%</b> untuk memastikan panggilan tidak berulang untuk  <b>Pelanggan</b> yang sudah terkontak dengan respons tertentu.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider horizontal><span style={{fontSize: '2em', color: '#11b1c3'}}>MONITORING</span></Divider>
          <Grid widths={16} stackable style={{margin: '1em 0 0em 0'}}>
            <Grid.Row>
              <Grid.Column width={10} style={{overflow: 'hidden'}}>
                <Image src={'https://www.sgrwin.com/wp-content/uploads/2021/07/dashboard2.png'} wrapped ui={false} />
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h2'>
                  <Header.Content>
                    <i>Real Time</i> Rekaman Audio
                    <Header.Subheader>Rekam pembicaraan dan langsung bisa dengarkan online saat selesai panggilan.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Rekaman audio panggilan kini dapat didengarkan saat itu juga ketika panggilan selesai, tinggal <cite>click</cite> dan <cite>play</cite> melalui aplikasi secara online, tanpa diunduh dan tanpa pemutar tertentu.
                </p>

                <Header as='h2'>
                  <Header.Content>
                    <i>Dashboard</i> Kontrol & Monitoring
                    <Header.Subheader><i>Real time</i>  <i>Agent</i>, <i>Campaign</i> dan <i>System</i> Monitoring.</Header.Subheader>
                  </Header.Content>
                </Header>
                <p>
                  Setiap aktivitas yang berkaitan dengan  <i>Agent</i>,  <i>Campaign </i> dan <i>System</i> disimpan dan ditampilkan oleh aplikasi dalam bentuk  <i>dashboard</i> yang mudah dimengerti dan dibaca oleh Admin atau pun  <i>Agent</i>.  Sehingga kondisi dan pencapaian dari setiap aktivitas termonitor dengan baik.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </main>
    )
  }
} 

export default FeaturePage
