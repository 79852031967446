import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import logo_deskphone from '../assets/logo-deskphone.png';
import logo_softphone from '../assets/logo-softphone.png';
import logo_flexible from '../assets/logo-flexible.png';
import logo_enterprise from '../assets/logo-enterprise.png';

import RingAnimate from './RingAnimate'

class ProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center', margin: '0'}}>
            <Header.Content>
              Solusi <i><b>Ring!</b> Office Phone</i>
              <div style={{fontWeight: '400', fontSize: '.8em'}}>Kolaborasi Semua <strong>Cabang Perusahaan</strong> <b>Tanpa Batas!</b></div>
            </Header.Content>
          </Header>

          <div style={{textAlign: 'center',marginBottom: '2em'}}>
            {/* <Button size='big' color='blue' onClick={this.props.openModal.bind(this, 'contact', {service: 'ring'})}>Hubungi Sales!</Button> */}
            <Button as='a' size='big' color='teal' href='/harga-terbaik-kolaborasi-perusahaan-tanpa-batas-dengan-fitur-enterprise/pricing/ring-office-phone/'>Cek Harga!</Button>
            
            <Divider hidden />
            <RingAnimate />
          </div>

          {/* <Divider horizontal>TENTANG DESK COLLECTION</Divider> */}
          <Container>
            <div style={{padding: screenWidth<1000 ? '1.5em' : '1.5em 10%', textAlign: 'center', marginBottom: '2em'}}>
              <p style={{fontSize: '1.5em'}}>
                Kolaborasi tanpa batas seluruh sumber daya <strong>Perusahaan</strong> di mana saja, kapan saja, menggunakan komunikasi berbasis Video, Suara dan Chat, hanya dengan menggunakan paket data. 
              </p>
              <p style={{fontSize: '1.5em'}}>
                Diakses dari <cite>Desk Phone</cite>, <cite>Web/Online App</cite> dan <cite>Mobile App</cite> dengan fitur <em>enterprise</em> (<em>IVR</em>, <em>Waiting</em>, <em>Forwarding</em>, <em>Mute/Unmute</em>, <em>Conference Call</em>) <strong>tanpa pulsa</strong>, <Label color='red' size='massive' style={{padding: '.2em .5em'}}>100% Hemat!</Label>.
              </p>
            </div>
          </Container>

          <div style={{background: '#f3f0d5', padding: screenWidth<1200 ? '1.5em 2em' : '1.5em 10%'}}>
            <Container>
              <Card.Group itemsPerRow={4} style={{padding: '3em 0 2em 0'}} stackable>
                <Card>
                  <Image alt="Desk Phone dan IP Phone" size='small' src={logo_deskphone} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Desk Phone
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                      Dengan pilihan desk phone yang bervariasi untuk keperluan kantor, yang mendukung Video, Suara dan Chat.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>
              
                <Card fluid>
                  <Image alt="Softphone Terintegrasi dalam Widget" src={logo_softphone} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Softphone
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        Layanan ini dapat ditambahkan di aplikasi kolaborasi internal perusahaan dengan bentuk widget yang siap pakai.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>

                <Card>
                  <Image alt="Adaptif dan Flexible Kustomisasi" size='small' src={logo_flexible} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Fleksibel
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        Di sisi aplikasi mobile, kami pastikan kendali penuh ada di tangan Anda, tanpa mengganggu kinerja dan tidak boros baterai.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>

                <Card>
                  <Image alt="Platform Terbaik Perusahaan" size='small' src={logo_enterprise} wrapped ui={false} style={{margin: '1.5em', padding:'1em'}}/>

                  <Card.Content style={{textAlign: 'center'}}>
                    <Card.Header>
                      <Header as='h2' color='blue'>
                        <Header.Content>
                          Enterprise
                        </Header.Content>
                      </Header>
                    </Card.Header>
                    <Card.Description>
                      <p>
                        Fitur siap pakai untuk perusahaan, <em>IVR</em>, <em>Forwarding</em>, <em>Audio Recording</em> dan <em>Conference Call</em>.
                      </p>
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Container>
          </div>
          
        </Container>
      </main>
    )
  }
} 

export default ProductPage
