import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class PricingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <div style={{display: 'flex'}}>
            <div style={{flex: '1', padding: '0 0'}}>
              <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
                <Header.Content>
                  Harga <em><b>Talk!</b> <strong>In-App Voice</strong></em> 
                  <div style={{fontSize: '.8em'}}>Komunikasi Aman antara <strong>Pelanggan</strong> dan <strong>Mitra / Merchant</strong></div>
                  <Header.Subheader style={{padding: '0 10%'}}>Implementasikan layanan ini di <i>Marketplace</i>, <i>Ride Sharing</i>, <i>Online Delivery</i>  untuk menjamin komunikasi aman.</Header.Subheader>
                </Header.Content>
              </Header>

              <div style={{padding: screenWidth>1000 ? '3em 15% 3em 15%' : '2em 1.5em', background: '#f9f6f0'}}>
                <div style={{textAlign: 'center', marginBottom: '3em'}}>
                  <Button size='big' color='red' onClick={this.props.openModal.bind(this, 'order', {service: 'talk'})}>Saya Tertarik !</Button>
                </div>

                <Divider />
                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={3}>
                          <Icon name='chart line' color='yellow' circular inverted style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Kapasitas Sambungan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Biaya dihitung kapasitas sambungan komunikasi secara bersamaan / <b>Call Line Pool</b>.
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>200K / Call Line Pool</strong>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          Biaya akun user Pelanggan yang dihitung berdasarkan koneksi maksimum user secara bersamaan ke sistem / <b>User Active Account Pool</b>.
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <strong style={{fontSize: '1.1em'}}>10K / User Active Account Line Pool </strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider />

                <Table basic='very' celled columns='16' unstackable>
                  <Table.Body>
                    <Table.Row>
                      {screenWidth>600 &&
                        <Table.Cell rowSpan={2}>
                          <Icon name='adjust' circular inverted color='yellow' style={{fontSize: '1.2em'}} />
                        </Table.Cell>
                      }
                      <Table.Cell width={10}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Opsi Tambahan Layanan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell width={6}>
                        <Header as='h2' color='blue'>
                          <Header.Content>
                            <strong>Biaya Langganan</strong>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>
                        <p style={{fontSize: '1.1em'}}>
                          <i>Auto Recording</i>, <i>Secure Communication</i> dan <i>On Premise</i> untuk memastikan data <strong>Pelanggan</strong> dan <strong>Mitra</strong> aman dan tetap di infrastruktur lokal.
                        </p>
                      </Table.Cell>
                      <Table.Cell>
                        <Button color='grey' size='small' onClick={this.props.openModal.bind(this, 'contact', {service: 'talk'})}>Hubungi Sales!</Button>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>

              <Divider hidden />
              <List as='ol' style={{color: '#333', fontSize: '.9em', padding: '0 1.5em'}}>
                <List.Item as='li' value='!'>
                  Biaya dihitung berdasarkan <strong>sambungan puncak secara bersamaan</strong> yang telah dipesan oleh <strong>Pelanggan</strong>.
                </List.Item>
                <List.Item as='li' value='!'>
                  Jumlah user yang digunakan baik untuk <strong>Pelanggan</strong> dan <strong>Mitra</strong> / <strong>Merchant</strong> tidak dihitung dan tidak dijadikan sebagai <i>cost structure</i>.
                </List.Item>
                <List.Item as='li' value='!'>
                  Untuk keperluan <strong>negosiasi</strong> harga atau <strong>kustomisasi</strong> baik layanan dan model bisnis silahkan hubungi sales kami untuk diskusi.
                </List.Item>
              </List>
            </div>
          </div>
        </Container>
      </main>
    )
  }
} 

export default PricingPage
