import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import axios from 'axios';
import { useParams } from "react-router-dom"

import DocFooter from './DocFooter'

class ContentPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  componentDidMount() {
    // console.log('!!! DID MOUNT ROUTE PROPS', this.props.routes);

    var scope= 'Stream! Outbound';
    var id= 0; 
    
    if (this.props.routes && this.props.routes['module']) {
      if (this.props.routes['module'].includes('stream')) {
        scope= 'Stream! Outbound';
      } else if (this.props.routes['module'].includes('flow')) {
        scope= 'Flow! Omni-Channel';
      } else if (this.props.routes['module'].includes('talk')) {
        scope= 'Talk! In-App Voice';
      } else if (this.props.routes['module'].includes('ring')) {
        scope= 'Ring! Office Phone';
      }
    } 
    if (this.props.routes && this.props.routes['id']) {
      id= this.props.routes['id'];
    }
    
    axios.post('https://crm.halopagi.com/proxy/connect/api/v1/document/get', {
      scope: scope,
      documentId: id,
    })
    .then(resp=>{
      console.log(resp.data);
      const { data }= resp.data;

      this.setState({
        document: data,
      })
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const { screenHeight, screenWidth, fullScreen }= this.props
    const { document }= this.state
    
    return(<>
      <main>
        <Container fluid style={{height: screenHeight, overflow: 'auto', padding: '3.5em 1.5em 0 1.5em', background: (document && !document.title) ? '#ccc' : '#fff'}}>
          {document && document.title && <article style={{maxWidth: (fullScreen ? screenWidth : '800px'), overflow: 'hidden', paddingBottom: '3em'}}>
            <div style={{textAlign: (screenWidth<640 ? 'right' : 'center'), marginBottom: '2.5em'}}>
              <Label as='a' color='red' circular icon  onClick={this.props.maximize.bind(this)}><Icon name={this.props.fullScreen ? 'window restore outline' : 'window maximize outline'} style={{margin: '0'}} /></Label>
            </div>
            <Header as='h1' style={{margin: '0 2em', display: 'block', textAlign: 'center'}} color='red'>
              <Header.Content style={{fontWeight: 'bold'}}>
                {document.title}
                {/* API Secret Key */}
                <Header.Subheader></Header.Subheader>
              </Header.Content>
            </Header>
            {document.image && <Image alt={document.title} style={{margin: '1em 0', height: '200px'}} src={document.image} />}
            <p style={{fontSize: '1.1em', margin: '0 3em 3em 3em ', textAlign: 'center', fontStyle: 'italic', color: '#555'}}>
              <Icon name='quote left' /> {document.content} <Icon name='quote right' />
            </p>

            <Divider />
            {document.contents && document.contents.map((item, i)=>{
              return(
                <article key={i} style={{paddingTop: '3em'}} id={i}>
                  <Header as='h3' color='blue'>
                    <Header.Content>
                      <strong>({i+1}) {item.headline}</strong>
                      <Header.Subheader>
                        {item.keyword && item.keyword.toSring}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                  <div>
                    {item.image && <Image ui={true} alt={item.headline} style={{margin: '1em auto', background: '#fafafa'}} src={item.image} />}
                    <div className='richcontent' dangerouslySetInnerHTML={{__html: item.content}} />
                  </div>
                </article>
              )
            })}
          </article>}
          {(document && !document.title) && <div style={{overflow: 'hidden', paddingBottom: '3em'}}>
            <Header as='h3' icon style={{display: 'block', margin: '40% 10%', background: '#eee', padding: '1em 1em'}}>
              <Icon name='warning sign' color='red' />
              <strong>Konten segera tersedia.</strong>
              <Header.Subheader>
                Silahkan update beberapa waktu ke depan.
              </Header.Subheader>
            </Header>
          </div>}
          
          <div style={{}}>
            {(document && document.title) && <DocFooter screenWidth={screenWidth} />}
          </div>
        </Container>
      </main>
    </>)
  }
} 

// export default ContentPage
export default (props) => (<ContentPage {...props} routes={useParams()} />);
