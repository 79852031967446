import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import ModalContainer from '../libraries/common/ModalContainer';

import { useParams } from "react-router-dom"


import RingHeader from './RingHeader'
import RingFooter from './RingFooter'
import ProductPage from './ProductPage'
import FeaturePage from './FeaturePage'
import PricingPage from './PricingPage'
import ConnectPage from './ConnectPage'

import ContactPage from '../home/ContactPage';

class RingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      modal: null,
    }
  }

  componentDidMount() {
    
  }

  closeModal(params) {
    this.setState({
      modal: null,
    })
  }
  
  openModal(value, params) {
    this.setState({
      modal: value,
      params: params,
    })
  }

  render() {
    const { screenHeight, screenWidth }= this.props

    // console.log('!!! ROUTE PROPS', this.props.routes)
    var path= 'home';
    
    if (this.props.path) {
      path= this.props.path;
    } else if (this.props.routes && this.props.routes['content']) {
      if (this.props.routes['content'].includes('pricing') || this.props.routes['content'].includes('harga')) {
        path= 'pricing';
      } else if (this.props.routes['content'].includes('api') || this.props.routes['content'].includes('connect')) {
        path= 'connect';
      }
    } else if (this.props.routes && this.props.routes['keyword']) {
      if (this.props.routes['keyword'].includes('pricing') || this.props.routes['keyword'].includes('harga')) {
        path= 'pricing';
      } else if (this.props.routes['keyword'].includes('api') || this.props.routes['keyword'].includes('connect')) {
        path= 'connect';
      }
    }

    return(<div>
      <RingHeader screenWidth={screenWidth} path={path} />
      
      {path=='home' && <ProductPage screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} modal={this.state.modal}  params={this.state.params}/> }
      {/* <FeaturePage screenWidth={screenWidth} screenHeight={screenHeight} /> */}
      {path=='pricing' && <PricingPage screenWidth={screenWidth} screenHeight={screenHeight} openModal={this.openModal.bind(this)} closeModal={this.closeModal.bind(this)} modal={this.state.modal} params={this.state.params} /> }
      {path=='connect' && <ConnectPage screenWidth={screenWidth} screenHeight={screenHeight} /> }

      <RingFooter screenWidth={screenWidth} />

      {(this.state.modal==='contact' || this.state.modal==='order') && <ModalContainer size='small' content={<ContactPage screenWidth={screenWidth} order={this.state.modal==='order'} screenHeight={screenHeight} closeModal={this.closeModal.bind(this)} params={this.state.params}/>} closeModal={this.closeModal.bind(this)} /> }
    </div>)
  }
} 

// export default RingPage
export default (props) => (<RingPage {...props} routes={useParams()}/>);