import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
  Search,
} from 'semantic-ui-react'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <nav>
        <Container style={{padding: '6em 1.5em'}}>
          {/* <Header as='h5' style={{display: 'block'}}>
            <Icon name='rocket' />
            <Header.Content>
              Cari Kontent
              <Header.Subheader>Cerita Seru.</Header.Subheader>
            </Header.Content>
          </Header> */}
          <Input fluid placeholder='Search...' />

          <Divider hidden />
          <Divider horizontal>
            <Label size='large' color='orange'>
              Cerita Terbaru
            </Label>
            {/* <Icon name='circle' /> */}
          </Divider>
          <Segment style={{margin: '0 -.9em'}}>
            <Header as='h5'>
              <Header.Content>
                <Header.Subheader>
                  09 Nov /21
                </Header.Subheader>
                Integrasi di klien PT. Halo Pagi
              </Header.Content>
            </Header>

            <Header as='h5'>
              <Header.Content>
                <Header.Subheader>
                  09 Nov /21
                </Header.Subheader>
                Integrasi di klien PT. Halo Pagi
              </Header.Content>
            </Header>
          </Segment>

          <Divider hidden />
          <Divider horizontal>
            <Label size='large' color='teal'>Cerita Terkait</Label>
            {/* <Icon name='circle' /> */}
          </Divider>
          <Segment style={{margin: '0 -.9em'}}>
            <Header as='h5'>
              <Header.Content>
                <Header.Subheader>
                  09 Nov /21
                </Header.Subheader>
                Integrasi di klien PT. Halo Pagi
              </Header.Content>
            </Header>

            <Header as='h5'>
              <Header.Content>
                <Header.Subheader>
                  09 Nov /21
                </Header.Subheader>
                Integrasi di klien PT. Halo Pagi
              </Header.Content>
            </Header>
          </Segment>
        </Container>
      </nav>
    )
  }
} 

export default IndexPage
