import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import logo from '../assets/logo.png';

class AboutusPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{minHeight: screenHeight, padding: '6em 1.5em', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              <strong>About <b>Us</b>  (<strike> !me </strike>)</strong>
              <div style={{fontSize: '.8em'}}><em>"Respect to Whom Behind this Project"</em></div>
            </Header.Content>
          </Header>
          
          
          <div style={{margin: '3em auto'}}>
            <Header as='h2'>
              <Header.Content>
              <Icon name='long arrow alternate right' /> <em>The <b>Company</b></em>
              </Header.Content>
            </Header>

            <Grid widths={16} stackable style={{textAlign: 'center'}}>
              <Grid.Row>
                <Grid.Column width={6} style={{padding: '1em 2em'}}>
                  <Image alt="Halo Pagi! Communication Platform" src={logo} size='medium' circular verticalAlign='middle' />
                </Grid.Column>
                <Grid.Column width={10} style={{padding: '1em 2em', textAlign: 'left'}}>
                  <p>
                    Komunikasi merupakan kebutuhan dasar saat ini, apalagi dengan tantangan baru berupa <b>pandemi</b> dari tahun 2019.  Hal inilah yang mendasari perlunya platform komunikasi yang terjangkau untuk semua perusahaan yang berasal dari tingkatan level yang berbeda baik secara kapitalisasi atau sumber daya.
                  </p>
                  <p>
                    Dengan layanan yang dibagi menjadi beberapa jenis yang disesuaikan dengan tujuan dan segmentasi perusahaan yang membutuhkan dari <i><b>Stream!</b> Outbound Campaign</i>, <i><b>Flow!</b> Omni-Channel</i>, <i><b>Talk!</b> In-App Voice</i> dan <i><b>Ring!</b> Office Phone</i>.
                  </p>
                  <p>
                    Mencoba memberikan kualitas terbaik dengan harga yang sangat terjangkau untuk memastikan semua perusahaan layak dan berhak mendapatkan layanan terbaik untuk kesuksesan kita bersama.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

          <div style={{margin: '3em auto'}}>
            <Header as='h2'>
              <Header.Content>
                <Icon name='long arrow alternate right' /> <em>Off-Screen <b>People</b></em>
              </Header.Content>
            </Header>
            
            <Grid widths={16} stackable style={{textAlign: 'center'}}>
              <Grid.Row>
                <Grid.Column width={6} style={{padding: '1em 2em'}}>
                  {/* <Image src='https://i.pinimg.com/originals/ba/bc/88/babc8834726480671d13ce482015d279.jpg' size='small' circular verticalAlign='middle' /> */}
                  <Icon name='building outline' size='huge' circular color='yellow' />
                </Grid.Column>
                <Grid.Column width={10} style={{padding: '1em 2em', textAlign: 'left'}}>
                  <Header as='h3'>
                    <Header.Content>
                      Digital Ecosystem Platform
                    </Header.Content>
                  </Header>
                  <p>
                    Mendefiniskan <em>technology stack</em> dan <em>environment</em> yang mendukung keberhasilan layanan, dari fase pengembangan, beta testing hingga fase komersialisasi.
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} style={{padding: '1em 2em'}}>
                  {/* <Image src='https://i.pinimg.com/originals/ba/bc/88/babc8834726480671d13ce482015d279.jpg' size='small' circular verticalAlign='middle' /> */}
                  <Icon name='building outline' size='huge' circular inverted color='yellow' />
                </Grid.Column>
                <Grid.Column width={10} style={{padding: '1em 2em', textAlign: 'left'}}>
                  <Header as='h3'>
                    <Header.Content>
                      Media Communication Platform
                    </Header.Content>
                  </Header>
                  <p>
                    Setiap komunikasi harus sudah dialirkan dalam media digital, hal ini lah yang harus dipastikan baik secara kualitas, penggunaan <em>bandwidth</em> dan keamanan media yang dikirimkan.
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} style={{padding: '1em 2em'}}>
                  {/* <Image src='https://i.pinimg.com/originals/ba/bc/88/babc8834726480671d13ce482015d279.jpg' size='small' circular verticalAlign='middle' /> */}
                  <Icon name='building outline' size='huge' circular color='yellow' />
                </Grid.Column>
                <Grid.Column width={10} style={{padding: '1em 2em', textAlign: 'left'}}>
                  <Header as='h3'>
                    <Header.Content>
                      Digital Development & Operation
                    </Header.Content>
                  </Header>
                  <p>
                    Setiap layanan akan berhenti pada satu titik dimana pengguna akan berinteraksi dengan layanan dalam bentuk aplikasi baik online atau mobile, kebutuhan ini yang harus dipastikan bahwa <em>experience</em> pengguna telah diakomodir.
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={6} style={{padding: '1em 2em'}}>
                  {/* <Image src='https://i.pinimg.com/originals/ba/bc/88/babc8834726480671d13ce482015d279.jpg' size='small' circular verticalAlign='middle' /> */}
                  <Icon name='building outline' size='huge' circular inverted color='yellow' />
                </Grid.Column>
                <Grid.Column width={10} style={{padding: '1em 2em', textAlign: 'left'}}>
                  <Header as='h3'>
                    <Header.Content>
                      Customer Insight & Validation
                    </Header.Content>
                  </Header>
                  <p>
                    Pengembangan layanan tidak lepas dari kebutuhan pengguna baik secara <em>experience</em> atau fungsionalitas. hal tersebut yang harus digali dan dipastikan bahwa layanan yang kita berikan  memang diperlukan oleh pengguna.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

        </Container>
      </main>
    )
  }
} 

export default AboutusPage
