import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class FlowFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <footer style={{background: '#fefefe', borderTop: '1px solid #eee'}}>
        <Container fluid style={{padding: '1em 0em 1.5em 0'}}>
          <div style={{display: screenWidth>600 ? 'flex' : 'block'}}>
            <div style={{flex: '1', padding: '.5em 1em 0 1em'}}>
              <List celled horizontal as='a'>
                <List.Item href='/about-us/'>About</List.Item>
                <List.Item href='/term-of-service/'>T.O.S</List.Item>
                <List.Item href='/disclaimer/'>Disclaimer</List.Item>
              </List>
            </div>
            <div style={{padding: '.25em 1em'}}>
              {/* {screenWidth<=600 && <Divider />} */}
              <Header as='a' style={{cursor: 'pointer', margin: 0}} href='/'>
                {/* <Icon name='copyright' color='red' style={{fontSize: '2em'}}/> */}
                <Image alt="Halo Pagi! Communication Platform" src={'https://halopagi.com/asset/logo-small.png'} verticalAlign='middle' />
                <Header.Content>
                  <b>Flow!</b> Omni-Channel 2.1
                  <Header.Subheader>
                    Definitely Your Communication Platform, by <strong>Halo Pagi!</strong>
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </div>
          </div>
        </Container>
      </footer>
    )
  }
} 

export default FlowFooter
