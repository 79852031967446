import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class ConnectPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      api: 'token'
    }
  }

  setApi(v) {
    this.setState({
      api: v,
    })
  }

  render() {
    const { api }= this.state
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              <b>Seamless Experience</b> <div style={{fontWeight: '400', fontSize: '.8em'}}>Integrasikan <strong><b>Talk!</b> In-App Voice</strong> di Mobile Application</div>
              <Header.Subheader style={{padding: '0 10%'}}>Pastikan komunikasi aman <strong>Pelanggan</strong> dan <strong>Mitra</strong> dengan menggunakan <b>API Connect</b>, di mobile app Anda.</Header.Subheader>
            </Header.Content>
          </Header>
          
          <Divider horizontal>Dukungan API Connect</Divider>
          <div style={{width: '100%', overflowX: 'auto', padding: '0 .5em'}}>
            <div style={{display: 'flex', textAlign: 'center', minWidth: (1100)}}>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'token')}>
                <Header as='h4' color={(api=='token' && 'blue') || 'black'} style={{background: ((api=='token' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='code'/> Token
                    <Header.Subheader>Pengaturan token akses.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='token' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'communication')}>
                <Header as='h4' color={(api=='communication' && 'blue') || 'black'} style={{background: ((api=='communication' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='phone'/> Communication
                    <Header.Subheader>Pengaturan aktivitas call.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='communication' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'interaction')}>
                <Header as='h4' color={(api=='interaction' && 'blue') || 'black'} style={{background: ((api=='interaction' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='comments' /> Interaction
                    <Header.Subheader>Riwayat transaksi call.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='interaction' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'webhook')}>
                <Header as='h4' color={(api=='webhook' && 'blue') || 'black'} style={{background: ((api=='webhook' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='plug' /> Webhook
                    <Header.Subheader>Notifikasi <i>real time</i> ke backend.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='webhook' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'reporting')}>
                <Header as='h4' color={(api=='reporting' && 'blue') || 'black'} style={{background: ((api=='reporting' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='file alternate outline' /> Reporting
                    <Header.Subheader>Download dan generasi laporan.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='reporting' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
            </div>
          </div>

          <div style={{padding: screenWidth>1400 ? '3em 15% 3em 15%' : '2em 1.5em', background: '#f9f6f0'}}>
            {api=='token' && <div>
              <Header as='h2' icon style={{textAlign: 'center', display: 'block'}} color='blue'>
                <Header.Content>
                  <Icon name='code' circular inverted color='yellow' />
                  <em>Token Management</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                <Table.Row>
                      <Table.Cell width={8}>
                        <Header as='h3'>
                          <Header.Content>
                          <em>Get Token</em>
                          </Header.Content>
                        </Header>
                        <p>
                          Mendapatkan token yang dapat digunakan periode tertentu sebagai properti <i>widget</i> yang dipakai.
                        </p>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <code>POST REQUEST</code>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <code>JSON RESPONSE</code>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={8}>
                        <Header as='h3'>
                          <Header.Content>
                          <em>Refresh Token</em>
                          </Header.Content>
                        </Header>
                        <p>
                          Bila diperlukan untuk kepentingan keamanan, dapat melakukan refresh token untuk mengganti token lama dengan token baru.
                        </p>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <code>POST REQUEST</code>
                      </Table.Cell>
                      <Table.Cell width={4}>
                        <code>JSON RESPONSE</code>
                      </Table.Cell>
                    </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            {api=='communication' && <div>
              <Header as='h2' icon style={{textAlign: 'center', display: 'block'}} color='blue'>
                <Header.Content>
                  <Icon name='phone' circular inverted color='yellow' />
                  <em>Communication Management</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Create Session</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Melakukan / membangun sesi komunikasi ke terminating user.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Destroy Session</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Memutuskan sesi komunikasi yang telah terinisiasi/terbentuk sebelumnya.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            
            {api=='interaction' && <div>
              <Header as='h2' icon style={{textAlign: 'center', display: 'block'}} color='blue'>
                <Header.Content>
                  <Icon name='comments' circular inverted color='yellow' />
                  <em>Interaction Management</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Get Interaction</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Melakukan kueri/permintaan informasi catatan komunikasi berdasarkan parameter pencarian tertentu.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            
            {api=='webhook' && <div>
              <Header as='h2' icon style={{textAlign: 'center', display: 'block'}} color='blue'>
                <Header.Content>
                  <Icon name='plug' circular inverted color='yellow' />
                  <em>Callback Webhook</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Call Event Callback</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mengirimkan callback event untuk setiap aktivitas komunikasi setelah proses selesai.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Message Event Callback</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Status komunikasi berbasis messaging setelah aktivitas komunikasi selesai.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            
            {api=='reporting' && <div>
              <Header as='h2' icon style={{textAlign: 'center', display: 'block'}} color='blue'>
                <Header.Content>
                  <Icon name='file alternate' circular inverted color='yellow' />
                  <em>Reporting Management</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <em>Report Status</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Meminta informasi proses generasi laporan apakah sudah selesai dan siap diunduh.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <em>Download Report</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mengirimkan permintaan untuk mengunduh laporan yang telah siap sesuai periode laporan yang diminta.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <em>Generate Report</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Meminta sistem untuk melakukan generasi ulang atau generasi manual periode laporan tertentu, disesuaikan kebutuhan.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      REQUEST
                    </Table.Cell>
                    <Table.Cell width={4}>
                      RESPONSE
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
          </div>

          <Divider hidden />
          <List as='ol' style={{color: '#333', fontSize: '.9em', padding: '0 1.5em'}}>
            <List.Item as='li' value='!'>
              Mekanisme API menggunakan <i>restful</i> berbasis POST dengan data /parameter yang dipertukarkan dalam format <b>JSON</b>.
            </List.Item>
            <List.Item as='li' value='!'>
              Format data yang dikirimkan / <i>REQUEST</i> dan diterima / <i>RESPONSE</i> dalam bentuk <b>JSON</b>, akan disampaikan dalam dokumen integrasi secara terpisah.
            </List.Item>
          </List>
        </Container>
      </main>
    )
  }
} 

export default ConnectPage
