import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Routes, Route  } from 'react-router-dom'

import HomePage from './home/HomePage';
import StreamPage from './stream/StreamPage';
import FlowPage from './flow/FlowPage';
import TalkPage from './talk/TalkPage';
import RingPage from './ring/RingPage';

import BlogPage from './blog/BlogPage';
import DocPage from './doc/DocPage';
import DisclaimerPage from './home/DisclaimerPage';
import TermofservicePage from './home/TermofservicePage';
import AboutusPage from './home/AboutusPage';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      screenHeight: 800,
      screenWidth: 1024,
    }
    this.updateDimension = this.updateDimension.bind(this)
  }

  componentDidMount() {
    this.updateDimension()
    window.addEventListener('resize', this.updateDimension)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimension)
    this.context.socket && this.context.socket.close();
  }
  
  updateDimension() {
    this.setState({ ...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight })
  }

	render() {

    // console.log('APP-PROPS', this.props);

    const { screenHeight, screenWidth }= this.state;
    return(
      <Routes>
        <Route path="/" element={<HomePage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/communication-platform" element={<HomePage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/stream-outbound" element={<StreamPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/flow-omni-channel" element={<FlowPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/talk-in-app-voice" element={<TalkPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/ring-office-phone" element={<RingPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        
        <Route path="/blog" element={<BlogPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/doc" element={<DocPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/doc/:module" element={<DocPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/:keyword/:id/doc/:module" element={<DocPage screenWidth={screenWidth} screenHeight={screenHeight} />} />

        <Route path='/:keyword/stream-outbound' element={<StreamPage screenWidth={screenWidth} path='home' screenHeight={screenHeight} />} />
        <Route path='/:keyword/flow-omni-channel' element={<FlowPage screenWidth={screenWidth} path='home' screenHeight={screenHeight} />} />
        <Route path='/:keyword/talk-in-app-voice' element={<TalkPage screenWidth={screenWidth} path='home' screenHeight={screenHeight} />} />
        <Route path='/:keyword/ring-office-phone' element={<RingPage screenWidth={screenWidth} path='home' screenHeight={screenHeight} />} />

        <Route path='/:keyword/:content/stream-outbound' element={<StreamPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path='/:keyword/:content/flow-omni-channel' element={<FlowPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path='/:keyword/:content/talk-in-app-voice' element={<TalkPage screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path='/:keyword/:content/ring-office-phone' element={<RingPage screenWidth={screenWidth} screenHeight={screenHeight} />} />

        <Route path="/about-us" element={<HomePage path='about-us' screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/term-of-service" element={<HomePage path='term-of-service' screenWidth={screenWidth} screenHeight={screenHeight} />} />
        <Route path="/disclaimer" element={<HomePage path='disclaimer' screenWidth={screenWidth} screenHeight={screenHeight} />} />
        
				<Route path='*' element={<StreamPage screenWidth={screenWidth} screenHeight={screenHeight} />} />

      </Routes>
    )
	}
}

export default App;