import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

import bg_stream_0 from '../assets/bg-flow-0.png';
import bg_stream_1 from '../assets/bg-flow-1.png';
import bg_stream_2a from '../assets/bg-flow-2a.png';
import bg_stream_2b from '../assets/bg-flow-2b.png';
import bg_stream_3 from '../assets/bg-flow-3.png';
// import bg_stream_3a from '../assets/bg-flow-3a.png';
// import bg_stream_3b from '../assets/bg-flow-3b.png';
import bg_stream_4 from '../assets/bg-flow-4.png';
import bg_stream_5 from '../assets/bg-flow-5.png';

const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = React.useState(false);
  
  // console.log('!!! USE EFFECT', element.current)
      
  React.useEffect(() => {

      const observer = new IntersectionObserver(
          ([entry]) => {
              if (entry.isIntersecting) {
                  setState(entry.isIntersecting);
                  observer.unobserve(element.current);
              } else {
                setState(false);
              }
          },
          {
              rootMargin
          }
      );

      element.current && observer.observe(element.current);

      return () => {
        element.current && observer.unobserve(element.current);
      };
  }, []);

  return isVisible;
};

// class StreamAnimate extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state= {
      
//     }
//     this.ref =  React.createRef();
//   }
  
//   componentDidMount() {
//     const inViewport = this.props.useIntersection && this.props.useIntersection(this.ref, "200");
//     if (inViewport) {
//         console.log("in viewport:", this.ref.current, inViewport);
//     }
//   }

//   render() {
//     const { screenHeight, screenWidth }= this.props
    
//     return(
//     <div style={{overflow: 'hidden', margin: '6em auto', textAlign: 'center', marginBottom: '2em'}} ref={this.ref}>
//       <div style={{height: '450px', background: '#ffffff url('+ bg_stream +') no-repeat center bottom'}} />
//     </div>
//     )
//   }
// } 

const StreamAnimate = (props) => {
  const ref = React.useRef();
  // const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
  const inViewport = useIntersection(ref, '-50%'); // Trigger if 200px is visible from the element
  if (inViewport) {
    console.log('!!! INVIEWPORT', ref.current)
  }
  return (
    <div style={{overflow: 'hidden', margin: '0 auto', textAlign: 'center'}} ref={ref}>
      <div style={{height: '450px', background: 'url('+ bg_stream_0 +') no-repeat center bottom'}} />
      {inViewport && <div className='animfadeout1' style={{visibility: 'hidden', marginTop: '-450px', height: '450px', background: 'url('+ bg_stream_1 +') no-repeat center bottom'}} />}
      {inViewport && <div className='animfadeout2a' style={{visibility: 'hidden', marginTop: '-450px', height: '450px', background: 'url('+ bg_stream_2a +') no-repeat center bottom'}} />}
      {inViewport && <div className='animfadeout2b' style={{visibility: 'hidden', marginTop: '-450px', height: '450px', background: 'url('+ bg_stream_2b +') no-repeat center bottom'}} />}
      {inViewport && <div style={{marginTop: '-450px', height: '450px'}}>
          <div className='animfadeout6' style={{visibility: 'hidden', height: '450px', background: 'url('+ bg_stream_3 +') no-repeat center top'}} />
        </div>}
      {/* {inViewport && <div className='animfadeout5' style={{visibility: 'hidden', margin: '-450px auto 0 auto', height: '450px', background: 'url('+ bg_stream_3a +') no-repeat center bottom'}} />} */}
      {/* {inViewport && <div className='animfadeout5' style={{visibility: 'hidden', margin: '-450px auto 0 auto', height: '450px', background: 'url('+ bg_stream_3b +') no-repeat center bottom'}} />} */}
      {inViewport && <div className='animfadeout4' style={{visibility: 'hidden', margin: '-450px auto 0 auto', height: '450px', background: 'url('+ bg_stream_4 +') no-repeat center bottom'}} />}
      {/* {inViewport && <div className='animfadeout5' style={{visibility: 'hidden', margin: '-450px auto 0 auto', height: '450px', background: 'url('+ bg_stream_4 +') no-repeat center bottom'}} />} */}
      {inViewport && <div className='animfadeout4' style={{visibility: 'hidden', margin: '-450px auto 0 auto', height: '450px', background: 'url('+ bg_stream_5 +') no-repeat center bottom'}} />}
    </div>
  );
}

export default StreamAnimate;
