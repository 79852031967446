import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class DisclaimerPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container style={{minHeight: screenHeight, padding: '6em 2em', background: '#fff'}}>
          <div style={{margin: '0 auto', maxWidth: '700px', textAlign: 'justify'}}>
            <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
              <Header.Content>
                <strong>Klausul <em>Disclaimer</em></strong>
                <div style={{fontSize: '.8em'}}>Hal-hal di Luar Tanggung Jawab <strong>Penyedia</strong> Layanan</div>
              </Header.Content>
            </Header>
          
            <div style={{margin: '3em auto',}}>
              <Header as='h2'>
                <Header.Content>
                <strong>1. Klaim Penerima Konten</strong>
                </Header.Content>
              </Header>
              <p>
                Setiap konten yang dikirimkan menjadi tanggung jawab sepenuhnya <strong>Perusahaan</strong> pengguna layanan, hal-hal yang terkait dengan konten dan proses penerimaan di sisi <strong>Penerima</strong> merupakan tanggung jawab <strong>Perusahaan</strong> pengguna layanan.
              </p>
              <p>
                <strong>Penerima</strong> tidak bisa melakukan klaim dan tuntutan terhadap <strong>Penyedia</strong> layanan, semua klaim dan tuntutan yang diterima dari <strong>Penerima</strong> oleh <strong>Penyedia</strong> layanan akan diteruskan ke <strong>Perusahaan</strong> pengguna layanan.
              </p>
            </div>

            <div style={{margin: '3em auto',}}>
              <Header as='h2'>
                <Header.Content>
                <strong>2. Downtime Layanan Akibat Pemeliharaan</strong>
                </Header.Content>
              </Header>
              <p>
                Layanan yang tidak dapat digunakan dikarenakan proses pemeliharaan yang telah diberikan informasi sebelumnya, tidak dapat dibebankan kepada <strong>Penyedia</strong> layanan.
              </p>
              <p>
                Informasi jadwal waktu pemeliharaan dan upgrade akan diinformasikan minimal tiga (3) hari sebelumnya dan dilakukan di waktu di luar jam-jam kantor.
              </p>
            </div>

            <div style={{margin: '3em auto',}}>
              <Header as='h2'>
                <Header.Content>
                  <strong>3. Kegagalan Operasional Dikarenakan Pihak Ketiga</strong>
                </Header.Content>
              </Header>
              <p>
                Kegagalan operasional dan ketersediaan layanan sehingga <strong>Perusahaan</strong> pengguna layanan tidak dapat memanfaatkan layanan secara maksimal dikarenakan gangguan dari <strong>Pihak Ketiga</strong> atau pihak luar seperti penyedia internet, data center, telekomunikasi sip trunk voip dan penyedia media pengiriman lainnya, tidak dapat dibebankan kepada <strong>Penyedia</strong> layanan.
              </p>
            </div>
          </div>

        </Container>
      </main>
    )
  }
} 

export default DisclaimerPage
