import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class RingHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth, path }= this.props
    
    return(
      <header>
        <Container fluid>
          <div style={{background: 'rgba(255, 203, 0, .95)', position: 'fixed', width: '100%', zIndex: '999', padding: '1em 1em .5em .3em'}}>
            <div style={{display: 'flex'}}>
              <Header as='a' style={{margin: '.1em 1em 0 1em', cursor: 'pointer'}} href='/'>
                <Icon name='long arrow alternate left' style={{fontSize: '1em'}} color='red' />
                {screenWidth>600 &&
                <Header.Content>
                  <b>Halo Pagi!</b> <span style={{color: '#444'}}>Communication Platform</span>
                </Header.Content>}
              </Header>
              <div style={{flex: '1'}}>
                
              </div>
              <nav>
                <div style={{display: 'flex'}}>
                  <div style={{borderRight: '1px solid #ccc'}}> 
                    <Header as='a' color={path=='home' || !path ? 'blue' : 'black'} style={{fontWeight: path=='home' || !path ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/kolaborasi-tanpa-batas-perusahaan-dengan-fitur-conference-call-ivr-auto-recording/ring-office-phone/'>
                      <Header.Content style={{fontWeight: 'bold'}}><b>Ring!</b> Office Phone</Header.Content>
                    </Header>
                  </div>
                  {/* <div style={{}}>
                    <Header as='a' color={path=='feature' ? 'red' : 'black'} style={{fontWeight: path=='feature' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/fitur-terbaik-ring-office-phone-untuk-interaksi-dengan-pelanggan/feature/ring-office-phone/'>
                      <Header.Content style={{fontWeight: 'bold'}}>Fitur</Header.Content>
                    </Header>
                  </div> */}
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='pricing' ? 'red' : 'black'} style={{fontWeight: path=='pricing' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/harga-terbaik-kolaborasi-perusahaan-tanpa-batas-dengan-fitur-enterprise/pricing/ring-office-phone/'>
                      <Header.Content style={{fontWeight: 'bold'}}>Harga {screenWidth>600 && 'Layanan'}</Header.Content>
                    </Header>
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='connect' ? 'red' : 'black'} style={{fontWeight: path=='connect' ? '600' : '300', margin: '0 1em', cursor: 'pointer'}} href='/integrasi-dengan-api-connect-untuk-mobile-app-internal/api-connect/ring-office-phone/'>
                      <Header.Content style={{fontWeight: 'bold'}}>API {screenWidth>600 && 'Connect'}</Header.Content>
                    </Header>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </Container>
      </header>
    )
  }
} 

export default RingHeader
