import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class SupplementPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth }= this.props
    
    return(
      <>
        <Divider />

        <Grid columns={2} stackable style={{margin: '2em 0 0 0', textAlign: 'center'}}>
          <Grid.Row style={{margin: '1em 0'}}>
            <Grid.Column style={{padding: '1em 2em'}}>
              <Header as='h2' icon color='blue'>
                <Icon name='headphones' style={{fontSize: '2em'}} color='yellow'/>
                <Header.Content>
                  Perangkat Pendukung
                  <Header.Subheader>Dukungan perangkat dan infrastruktur operasional.</Header.Subheader>
                </Header.Content>
              </Header>
              <p>
                <i> Agent </i> hanya memerlukan peralatan audio <b>headphone</b> dan <b>laptop</b> yang mendukung browser chrome, tanpa perlu perangkat lainnya, baik hardware atau software lainnya.
              </p>
            </Grid.Column>
            <Grid.Column style={{padding: '1em 2em'}}>
              <Header as='h2' icon color='blue'>
                <Icon name='folder outline' style={{fontSize: '2em'}} color='yellow'/>
                <Header.Content>
                  Data <strong>Pelanggan</strong>
                  <Header.Subheader>Mekanisme pengendalian keamanan data <strong>Pelanggan</strong>.</Header.Subheader>
                </Header.Content>
              </Header>
              <p>
                Mekanisme <i>Data Masking</i> dilakukan agar data tidak bisa dibaca oleh pihak tidak berkepentingan dan <i>Local Relay</i> untuk memastikan data tetap berada di jaringan lokal.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '1em 0'}}>
            <Grid.Column style={{padding: '1em 2em'}}>
              <Header as='h2' icon color='blue'>
                <Icon name='chart line' style={{fontSize: '2em'}} color='yellow'/>
                <Header.Content>
                  Performansi <i> Agent </i>
                  <Header.Subheader>Jaminan performansi berbasis ekosistem platform.</Header.Subheader>
                </Header.Content>
              </Header>
              <p>
              <i> Agent </i> selalu dalam performansi tinggi dengan ekosistem yang didesain sedemikian rupa, dari <i>Sign In</i> hingga <i>On Call</i>, <i>Conversation Timer</i> dan mekanisme <i>Push Dispatch</i> dimana <i> Agent </i> akan otomatis menerima panggilan.
              </p>
            </Grid.Column>
            <Grid.Column style={{padding: '1em 2em'}}>
              <Header as='h2' icon color='blue'>
                <Icon name='microphone' style={{fontSize: '2em'}} color='yellow'/>
                <Header.Content>
                  Softphone Terintegrasi
                  <Header.Subheader>Tidak perlu <i>softphone</i> yang terinstall terpisah.</Header.Subheader>
                </Header.Content>
              </Header>
              <p>
                Untuk komunikasi dengan berbasis telepon, kini tidak perlu menginstall <i>softphone</i> terpisah di luar aplikasi, dikarenakan telah terintegrasi sehingga status <i> Agent </i> dapat dimonitor dengan lebih detail.
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row style={{margin: '1em 0'}}>
            <Grid.Column style={{padding: '1em 2em'}}>
              <Header as='h2' icon color='blue'>
                <Icon name='heartbeat' style={{fontSize: '2em'}} color='yellow'/>
                <Header.Content>
                  Kontrol dan Monitoring
                  <Header.Subheader>Model bisnis dan waktu instalasi layanan.</Header.Subheader>
                </Header.Content>
              </Header>
              <p>
                Monitoring performansi <i> Agent </i> dan Campaign Anda secara langsung dari satu pintu dashboard, mulai dari delivered, contacted, invalid, rejected, mailbox, rescheduled, paid dan promised.
              </p>
            </Grid.Column>
            <Grid.Column style={{padding: '1em 2em'}}>
              <Header as='h2' icon color='blue'>
                <Icon name='calendar alternate outline' style={{fontSize: '2em'}} color='yellow'/>
                <Header.Content>
                  Biaya dan Waktu
                  <Header.Subheader>Model bisnis dan waktu instalasi layanan.</Header.Subheader>
                </Header.Content>
              </Header>
              <p>
                Layanan secara instan dapat digunakan, dengan pilihan model bisnis beragam yang sesuai kebutuhan Anda, dari <i>Pay Per Use</i>, <i>Seat / User</i> atau <i>CPU / Server</i> memungkinkan Anda untuk mendapatkan harga terbaik.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }
} 

export default SupplementPage
