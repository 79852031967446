import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
} from 'semantic-ui-react'

class ConnectPage extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      api: 'widget'
    }
  }

  setApi(v) {
    this.setState({
      api: v,
    })
  }

  render() {
    const { api }= this.state
    const { screenHeight, screenWidth }= this.props
    
    return(
      <main>
        <Container fluid style={{minHeight: screenHeight, padding: '6em 0', background: '#fff'}}>
          <Header as='h1' style={{display: 'block', textAlign: 'center'}}>
            <Header.Content>
              <b>Seamless Experience</b> <div style={{fontWeight: '400', fontSize: '.8em'}}>Akses Fitur <i>Omni-Channel</i> di Aplikasi Lokal</div>
              <Header.Subheader style={{padding: '0 10%'}}>Hubungkan aplikasi internal Anda dengan menggunakan <b>API Connect</b>, untuk memastikan semua fitur komunikasi dapat diakses secara lokal.</Header.Subheader>
            </Header.Content>
          </Header>
          
          <Divider horizontal>Dukungan API Connect</Divider>
          <div style={{width: '100%', overflowX: 'auto', padding: '0 .5em'}}>
            <div style={{display: 'flex', textAlign: 'center', minWidth: (1100)}}>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'widget')}>
                <Header as='h4' color={(api=='widget' && 'blue') || 'black'} style={{background: ((api=='widget' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='code'/> Widget
                    <Header.Subheader>Pengaturan <i>widget</i>  di App.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='widget' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'campaign')}>
                <Header as='h4' color={(api=='campaign' && 'blue') || 'black'} style={{background: ((api=='campaign' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='calendar alternate outline'/> Campaign
                    <Header.Subheader>Pengaturan <i>campaign</i>.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='campaign' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'contact')}>
                <Header as='h4'  color={(api=='contact' && 'blue') || 'black'}style={{background: ((api=='contact' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='user circle outline' /> Target
                    <Header.Subheader>Menentukan data target.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='contact' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'agent')}>
                <Header as='h4' color={(api=='agent' && 'blue') || 'black'} style={{background: ((api=='agent' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='spy' /> Agent
                    <Header.Subheader>Pengaturan status Agent.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='agent' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'webhook')}>
                <Header as='h4' color={(api=='webhook' && 'blue') || 'black'} style={{background: ((api=='webhook' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='plug' /> Webhook
                    <Header.Subheader>Notifikasi ke backend.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='webhook' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
              <div style={{flex: 1, margin: '.1em', cursor: 'pointer'}} onClick={this.setApi.bind(this, 'reporting')}>
                <Header as='h4' color={(api=='reporting' && 'blue') || 'black'} style={{background: ((api=='reporting' && '#fafafa') || '#fff'), borderRadius: '.5em', padding: '.5em', border: '1px solid rgba(25, 25, 25, .05)'}}>
                  <Header.Content>
                    <Icon name='file alternate outline' /> Reporting
                    <Header.Subheader>Pengaturan laporan.</Header.Subheader>
                  </Header.Content>
                </Header>
                {api=='reporting' && <Divider horizontal><Icon name='circle' color='red' /></Divider>}
              </div>
            </div>
          </div>

          <div style={{padding: screenWidth>1400 ? '3em 15% 3em 15%' : '2em 1.5em', background: '#eaf7f7'}}>
            {api=='widget' && <div>
              <Header as='h2' color='blue' icon style={{textAlign: 'center', display: 'block'}}>
                <Header.Content>
                  <Icon name='code' circular inverted color='yellow' />
                  <em>Widget Configuration</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Get Snippet</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mendapatkan <i>snippet code</i> untuk bisa dipakai di environment platform yang telah didukung (Web/Android).
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Get Token</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mendapatkan token yang dapat digunakan periode tertentu sebagai properti <i>widget</i> yang dipakai.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Refresh Token</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Bila diperlukan untuk kepentingan keamanan, dapat melakukan refresh token untuk mengganti token lama dengan token baru.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}

            {api=='campaign' && <div>
              <Header as='h2' color='blue' icon style={{textAlign: 'center', display: 'block'}}>
                <Header.Content>
                  <Icon name='calendar alternate' circular inverted color='yellow' />
                  <em>Campaign Management</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Create Campaign</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Menambahkan campaign baru ke dalam sistem agar dapat dieksekusi berdasarkan jadwal yang telah ditentukan.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Update Campaign</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Memperbarui informasi dan parameter data campaign bilamana diperlukan.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Disable Campaign</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Bilamana diperlukan suatu campaign dapat diubah statusnya menjadi disabled untuk memastikan campaign tersebut berhenti / tidak berjalan.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Call Ratio / Attempt Ratio</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Seiring berjalannya campaign, seringkali diperlukan tuning performansi untuk memastikan Agent mempunyai okupansi tinggi, dengan mengatur parameter call ratio / attempt ratio.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            
            {api=='contact' && <div>
              <Header as='h2' color='blue' icon style={{textAlign: 'center', display: 'block'}}>
                <Header.Content>
                  <Icon name='user circle' circular inverted color='yellow' />
                  <em>Contact Management</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Push Target Queue</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Memasukkan data target ke antrian campaign tertentu yang didefinisikan atau akan dibuat oleh sistem secara otomatis.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Disable Target Queue</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mendefinisikan target tertentu untuk didisabled  agar memastikan bahwa target tersebut tidak akan dikirim informasi.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Status Of Target Queue</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Meminta informasi untuk kelompok antrian tertentu di campaign apakah sudah selesai atau belum, bilamana target dibagi menjadi beberapa antrian yang terpisah.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Update Specific Individual Target</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Memperbarui informasi target tertentu yang telah dimasukkan ke sistem.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            
            {api=='agent' && <div>
              <Header as='h2' color='blue' icon style={{textAlign: 'center', display: 'block'}}>
                <Header.Content>
                  <Icon name='spy' circular inverted color='yellow' />
                  <em>Agent Controller</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Sign In</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mengirimkan permintaan berdasarkan Agent tertentu, untuk diproses dan diubah dalam status Sign In.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Sign Out</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Memberitahukan bahwa Agent tertentu telah melakukan Sign Out, data terkait session dan status panggilan ke Voip akan di take down.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Start Session</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Meminta status Agent tertentu untuk dirubah dalam status Online dan tersedia untuk menerima call dispatch dari Pelanggan.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Lock Session</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Merubah status Agent dari Online menjadi Locked, agar untuk sementara waktu Agent tidak menerima call dispatch dikarenakan keperluan sesaat.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Unlock Session</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mengembalikan status Agent menjadi Online dan tersedia untuk menerima call dispatch.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              
              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Call Customer</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Melakukan panggilan ke kontak target tertentu secara manual / preview, bilamana Agent melakukan Click To Call di aplikasi internal.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Hangup Customer</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Memutuskan panggilan Pelanggan yang telah terhubung dikarenakan pembicaraan telah selesai.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            
            {api=='webhook' && <div>
              <Header as='h2' color='blue' icon style={{textAlign: 'center', display: 'block'}}>
                <Header.Content>
                  <Icon name='plug' circular inverted color='yellow' />
                  <em>Callback Webhook</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Agent Event Callback</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mengirimkan callback event untuk setiap aktivitas Agent seperti Sign In, Sign Out, Start Session, Stop Session, Locked dan Unlocked.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Delivery Event Callback</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Status pengiriman informasi dan status panggilan akan diinformasikan secara real time seperti Call Picked Up, Call Hungup dan Delivered, Read untuk messaging.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Remark/Feedback Event Callback</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Setiap pembicaraan dengan Pelanggan telah selesai dilakukan, input data feedback / remark yang disimpan dalam sistem akan dikirim secara real time ke aplikasi internal.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                        <em>Target's Queue Event Callback</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mengirimkan informasi status antrian apakah masih tersedia atau telah selesai diproses.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
            
            {api=='reporting' && <div>
              <Header as='h2' color='blue' icon style={{textAlign: 'center', display: 'block'}}>
                <Header.Content>
                  <Icon name='file alternate' circular inverted color='yellow' />
                  <em>Reporting Management</em>
                </Header.Content>
              </Header>

              <Divider />
              <Table basic='very' celled columns='16' unstackable>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <em>Report Status</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Meminta informasi proses generasi laporan apakah sudah selesai dan siap diunduh.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <em>Download Report</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Mengirimkan permintaan untuk mengunduh laporan yang telah siap sesuai periode laporan yang diminta.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={8}>
                      <Header as='h3'>
                        <Header.Content>
                          <em>Generate Report</em>
                        </Header.Content>
                      </Header>
                      <p>
                        Meminta sistem untuk melakukan generasi ulang atau generasi manual periode laporan tertentu, disesuaikan kebutuhan.
                      </p>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>POST REQUEST</code>
                    </Table.Cell>
                    <Table.Cell width={4}>
                      <code>JSON RESPONSE</code>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>}
          </div>

          <Divider hidden />
          <List as='ol' style={{color: '#333', fontSize: '.9em', padding: '0 1.5em'}}>
            <List.Item as='li' value='!'>
              Mekanisme API menggunakan <i>restful</i> berbasis POST dengan data /parameter yang dipertukarkan dalam format <b>JSON</b>.
            </List.Item>
            <List.Item as='li' value='!'>
              Format data yang dikirimkan / <i>REQUEST</i> dan diterima / <i>RESPONSE</i> dalam bentuk <b>JSON</b>, akan disampaikan dalam dokumen integrasi secara terpisah.
            </List.Item>
          </List>
        </Container>
      </main>
    )
  }
} 

export default ConnectPage
