import React from 'react'
import {
  Container,
  Divider,
  Dropdown,
  Grid,
  Icon,
  Header,
  Image,
  List,
  Menu,
  Segment,
  Input,
  Card,
  Button,
  Step,
  Form,
  Tab,
  Accordion,
  Label,
  Table,
  Checkbox,
  Popup,
} from 'semantic-ui-react'

const stylePopup = {
  borderRadius: '.3em 0',
  textAlign: 'justify',
  opacity: 1,
  padding: '.5em .8em',
  fontSize: '.8em',
  background: '#000',
  color: '#fff'
}

class StreamHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state= {
      
    }
  }

  render() {
    const { screenHeight, screenWidth, path }= this.props
    
    return(
      <header>
        <Container fluid>
          <div style={{background: 'rgba(255, 203, 0, .95)', position: 'fixed', width: '100%', zIndex: '999', padding: '1.5em .5em .5em .5em'}}>
            <div style={{display: 'flex'}}>
              <Header as='a' style={{margin: '.1em 1em 0 1em', cursor: 'pointer'}} href='/'>
                <Icon name='long arrow alternate left' style={{fontSize: '1em'}} color='red' />
                {screenWidth>600 &&
                <Header.Content>
                  <b>Halo Pagi!</b> <span style={{color: '#444'}}>Communication Platform</span>
                </Header.Content>}
              </Header>
              <div style={{flex: '1'}}>
                
              </div>
              <nav>
                <div style={{display: 'flex', textAlign: 'center'}}>
                  <div style={{borderRight: '1px solid #ccc'}}> 
                    <Header as='a' color={path=='home' || !path ? 'blue' : 'black'} style={{fontWeight: path=='home' || !path ? '600' : '300', margin: '0 1em'}} href='/satu-platform-outbound-campaign-untuk-desk-collection-telesales-survey-verification/stream-outbound/'>
                      <Header.Content style={{fontWeight: 'bold'}}><b>Stream!</b> Outbound</Header.Content>
                    </Header>
                  </div>
                  {/* <div style={{}}>
                    <Header as='a' color={path=='feature' ? 'red' : 'black'} style={{fontWeight: path=='feature' ? '600' : '300', margin: '0 1em'}} href='/fitur-terbaik-stream-outbound-campaign-untuk-desk-collection-telesales-survey-verification/feature/stream-outbound/'>
                      <Header.Content style={{fontWeight: 'bold'}}>Fitur</Header.Content>
                    </Header>
                  </div> */}
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Popup style={stylePopup} content='Pilihan harga terbaik!' position= 'bottom right' inverted
                      trigger={
                      <Header as='a' color={path=='pricing' ? 'red' : 'black'} style={{fontWeight: path=='pricing' ? '600' : '300', margin: '0 1em'}} href='/pilihan-harga-terbaik-lisensi-seat-user-dan-bundling-voice-untuk-desk-collection-telesales-survey-verification/pricing/stream-outbound/'>
                        <Header.Content style={{fontWeight: 'bold'}}>
                          Harga {screenWidth>600 && 'Layanan'}
                          <Label color='red' style={{position: 'fixed', margin: '-1.8em 0 0 -3.8em', padding: '.4em .4em .3em .4em', borderRadius: '.2em'}}>WOW !</Label>
                        </Header.Content>
                      </Header>
                    } />
                    
                  </div>
                  <div style={{borderLeft: '1px solid #ccc'}}>
                    <Header as='a' color={path=='connect' ? 'red' : 'black'} style={{fontWeight: path=='connect' ? '600' : '300', margin: '0 1em'}} href='/integrasi-dengan-api-connect-untuk-aplikasi-backend-crm-billing-perusahaan/api-connect/stream-outbound/'>
                      <Header.Content style={{fontWeight: 'bold'}}>API {screenWidth>600 && 'Connect'}</Header.Content>
                    </Header>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </Container>
      </header>
    )
  }
} 

export default StreamHeader
